<template>
  <div class="element-area">
    <input
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      :style="dynamicWidth"
      ref="typeInput"
      :max="max"
      :min="min"
      :maxlength="maxLength"
      @keyup="saveInput"
    />
    <RegularInputIcon
      v-if="icon"
      :icon="icon"
      @submitInput="executeIconAction"
    />
  </div>
</template>

<script>
import RegularInputIcon from "./RegularInputIcon.vue";

export default {
  components: {
    RegularInputIcon,
  },

  emits: ["submitInput", "update:modelValue"],

  props: {
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    clearOnSubmit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    widthMultiplier: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    maxLength: {
      type: String,
      required: false,
    },
    iconAction: {
      type: String,
      required: false,
      default: "focus",
    },
  },

  computed: {
    dynamicWidth() {
      return {
        width: this.placeholder.length * this.widthMultiplier + "ch",
      };
    },
  },

  methods: {
    saveInput(event) {
      this.$emit("update:modelValue", this.$refs.typeInput.value);

      if (event.key === "Enter" && this.clearOnSubmit) {
        this.$emit("submitInput", this.$refs.typeInput.value);
        this.$refs.typeInput.value = "";
      }
    },
    executeIconAction() {
      if (this.iconAction === "enter") {
        this.saveInput({ key: "Enter" });
      } else if (this.iconAction === "focus") {
        this.$refs.typeInput.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.element-area {
  display: flex;
  align-items: center;
  padding: 0 2rem 1rem;
  border-bottom: 1px solid colors.$brightTurquoise;
}

input {
  background: none;
  color: colors.$brightTurquoise;
  border: none;
  @include type.bodyThree;
  text-align: center;

  &:focus {
    outline: none;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus::-ms-input-placeholder {
    color: transparent;
  }

  &:invalid {
    color: colors.$watermelonSignal;
    outline: none;
    box-shadow: none;
    background: transparent;

    &:focus {
      color: colors.$brightTurquoise;
    }
  }
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: colors.$brightTurquoise;
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: colors.$brightTurquoise;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
