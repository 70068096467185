<template>
  <footer>
    <span>made for you by</span>
    <img src="../../assets/mares-signature.svg" alt="mares zhar" />
  </footer>
</template>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
}

span {
  @include type.captionTwo;
  color: colors.$grayThree;
  margin-right: 3rem;
}

img {
  width: clamp(90px, 8.15vw, 250px);
  padding-bottom: 1.1rem;
}
</style>
