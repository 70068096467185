export default {
  namespaced: true,
  state() {
    return {
      commands: [],
      newCommandTemplate: {
        name: "",
        type: ["direct", "inclusive"],
        listedSources: [],
        interval: {
          isActiveAllDay: false,
          startTime: {
            hours: 11,
            minutes: 0,
          },
          endTime: {
            hours: 19,
            minutes: 30,
          },
        },
        dailyLimit: null,
        activeDays: ["Mo", "Tu", "We", "Th", "Fr"],
        isEnabled: true,
      },
      commandToEdit: null,
      isFetchingCommands: false,
      lastCommandsChange: null,
    };
  },
  getters: {
    commands(state) {
      return state.commands;
    },
    newCommandTemplate(state) {
      return state.newCommandTemplate;
    },
    commandToEdit(state) {
      return state.commandToEdit;
    },
    isFetchingCommands(state) {
      return state.isFetchingCommands;
    },
    lastCommandsChange(state) {
      return state.lastCommandsChange;
    },
  },
  mutations: {
    setPlatformCommands(state, platformCommands) {
      const commands = [];

      for (const platform in platformCommands) {
        for (const commandId in platformCommands[platform]) {
          if (!platformCommands[platform][commandId].listedSources) {
            platformCommands[platform][commandId].listedSources = [];
          }

          commands.push({
            id: commandId,
            platform: platform,
            ...platformCommands[platform][commandId],
          });
        }
      }

      commands.sort((a, b) => {
        return b.lastModified - a.lastModified;
      });

      state.commands = commands;
    },
    toggleCommand(state, index) {
      state.commands[index].isEnabled = !state.commands[index].isEnabled;
    },
    clearCommandToEdit(state) {
      state.commandToEdit = null;
    },
    setCommandToEdit(state, commandToEdit) {
      state.commandToEdit = commandToEdit;
    },
    setFetchingStatus(state, status) {
      state.isFetchingCommands = status;
    },
    updateLastCommandsChange(state) {
      state.lastCommandsChange = Date.now();
    },
  },
  actions: {
    async fetchCommands(context) {
      context.commit("setFetchingStatus", true);

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/commands/${userId}.json?auth=${token}`
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      const allCommands = await response.json();

      context.commit("setFetchingStatus", false);
      context.commit("setPlatformCommands", allCommands);
    },

    async toggleCommand(context, { commandId, index, isEnabled }) {
      context.commit("toggleCommand", index);

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/commands/${userId}/YouTube/${commandId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            isEnabled: !isEnabled,
          }),
        }
      );

      if (!response.ok) {
        console.log(response);
        // reverse toggling to show it didn't go through
        context.commit("toggleCommand", index);
        return;
      }

      context.commit("updateLastCommandsChange");
    },

    setCommandToEdit(context, commandId) {
      const commandToEdit = context.getters.commands.find(
        (command) => command.id === commandId
      );

      context.commit("setCommandToEdit", commandToEdit);
    },

    clearCommandToEdit(context) {
      context.commit("clearCommandToEdit");
    },

    async saveCommand(context, generatedCommand) {
      const commandId = context.getters.commandToEdit?.id || null;
      const platform = context.getters.commandToEdit?.platform || "YouTube";

      generatedCommand.lastModified = Date.now();

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      let targetURL = `${databaseURL}/commands/${userId}/${platform}`;
      let method = "POST";

      if (commandId) {
        targetURL += `/${commandId}.json?auth=${token}`;
        method = "PUT";
      } else {
        targetURL += `.json?auth=${token}`;
      }

      const response = await fetch(targetURL, {
        method: method,
        body: JSON.stringify(generatedCommand),
      });

      if (!response.ok) {
        console.error("Failed to save command");
        console.log(response);
        return;
      }

      context.commit("updateLastCommandsChange");
      context.dispatch("fetchCommands");
    },

    async deleteCommand(context, { commandId, platform }) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const targetUrl = `${databaseURL}/commands/${userId}/${platform}/${commandId}.json?auth=${token}`;

      const response = await fetch(targetUrl, {
        method: "DELETE",
      });

      if (!response.ok) {
        console.log(response);
        console.error("Failed to delete command.");

        return;
      }

      context.commit("updateLastCommandsChange");
      context.dispatch("fetchCommands");
    },
  },
};
