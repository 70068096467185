<template>
  <div class="toggle">
    <div :class="selectedA" @click="select(valueA)">
      {{ optionA }}
    </div>
    <div :class="selectedB" @click="select(valueB)">
      {{ optionB }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionA: {
      type: String,
      required: true,
    },
    optionB: {
      type: String,
      required: true,
    },
    valueA: {
      required: true,
    },
    valueB: {
      required: true,
    },
    aIsSelected: {
      type: Boolean,
      default: true,
      required: true,
    },
  },

  emits: ["selectionUpdate"],

  computed: {
    selectedA() {
      return {
        selected: this.aIsSelected,
      };
    },
    selectedB() {
      return {
        selected: !this.aIsSelected,
      };
    },
  },

  methods: {
    select(selectedValue) {
      this.$emit("selectionUpdate", selectedValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  background: colors.$graySix;
  color: colors.$grayTwo;
  @include type.bodyThree;

  display: flex;
  width: max-content;
  border-radius: 0.65em;
  overflow: hidden;

  div {
    padding: 1rem 3.5rem;
    cursor: pointer;
  }

  .selected {
    background: colors.$softPurple;
    color: colors.$distantStar;
    @include type.bodyTwo;
  }
}
</style>
