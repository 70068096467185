import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./data/store";
import { configForOutclosableDirective } from "./helpers/configForOutclosableDirective";

const app = createApp(App);

app.use(store);
app.use(router);

// Register custom directives:
app.directive("outclosable", configForOutclosableDirective); // enables dialog closing on clicks outside element

app.mount("#app");
