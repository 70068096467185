<template>
  <defs>
    <linearGradient
      id="svg-aurora-gradient"
      x1="-0.0432434"
      y1="2.4412"
      x2="27.7009"
      y2="29.0257"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#6A52E0" />
      <stop offset="1" stop-color="#52E0DC" />
    </linearGradient>
  </defs>
</template>
