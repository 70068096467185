export default {
  namespaced: true,
  state() {
    return {
      savedBossHaven: "",
      savedDaysStartAt: {
        hours: 12,
        minutes: 0,
      },
      isFetchingSettings: false,
      lastSettingsChange: null,
    };
  },
  getters: {
    savedBossHaven(state) {
      return state.savedBossHaven;
    },
    savedDaysStartAt(state) {
      return state.savedDaysStartAt;
    },
    lastSettingsChange(state) {
      return state.lastSettingsChange;
    },
  },
  mutations: {
    setFetchingStatus(state, status) {
      return (state.isFetchingSettings = status);
    },
    setSettings(state, { bossHaven, daysStartAt }) {
      state.savedBossHaven = bossHaven;
      state.savedDaysStartAt = daysStartAt;
    },
    updateLastSettingsChange(state) {
      state.lastSettingsChange = Date.now();
    },
  },
  actions: {
    async fetchSettings(context) {
      context.commit("setFetchingStatus", true);

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/userSettings/${userId}/globalSettings.json?auth=${token}`
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      const globalSettings = await response.json();

      context.commit("setFetchingStatus", false);

      context.commit("setSettings", globalSettings);
    },
    async updateBossHaven(context, url) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/userSettings/${userId}/globalSettings.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            bossHaven: url,
          }),
        }
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      context.commit("updateLastSettingsChange");
      context.dispatch("fetchSettings");
      context.dispatch(
        "setPageSignal",
        {
          icon: "tickMark",
          message: "Saved!",
          type: "positive",
        },
        { root: true }
      );
    },
    async updateDaysStartAt(context, daysStartAt) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/userSettings/${userId}/globalSettings.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            daysStartAt,
          }),
        }
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      context.commit("updateLastSettingsChange");
      context.dispatch("fetchSettings");
      context.dispatch(
        "setPageSignal",
        {
          icon: "tickMark",
          message: "Saved!",
          type: "positive",
        },
        { root: true }
      );
    },
    async updateUserName(context, userName) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/basicInfo/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: userName,
          }),
        }
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      context.dispatch("fetchBasicInfo", null, { root: true });
      context.dispatch(
        "setPageSignal",
        {
          icon: "tickMark",
          message: "Saved!",
          type: "positive",
        },
        { root: true }
      );
    },
  },
};
