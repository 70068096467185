<template>
  <main>
    <div class="wrapper nested">
      <section>
        <h2>Command Settings</h2>
        <div class="block block--vertical nested">
          <div class="row">
            <span class="block__label">BOSS Haven:</span>
            <p class="guiding-text">When a video is blocked, redirect me to</p>
            <RegularTypeInput
              placeholder="(empty, type a URL)"
              icon="link"
              :widthMultiplier="1.65"
              v-model.trim="bossHaven"
            />
            <button
              class="save"
              type="button"
              v-if="bossHavenHasChanged"
              @click="updateBossHaven"
            >
              Save
            </button>
          </div>
          <p class="informative-text">
            (If you leave this redirect URL empty, when a video is blocked its
            tab will be closed.)
          </p>
        </div>
        <div class="block block--vertical nested">
          <div class="row">
            <span class="block__label">Days Start At:</span>
            <RegularTypeInput
              :placeholder="'  '"
              v-model.trim="daysStartAtHours"
              type="number"
              :max="12"
              :min="0"
            />
            <span class="guiding-text colons">:</span>
            <RegularTypeInput
              :placeholder="'  '"
              v-model.trim="daysStartAtMinutes"
              type="number"
              :max="59"
              :min="0"
            />
            <LabeledToggle
              optionA="am"
              optionB="pm"
              valueA="am"
              valueB="pm"
              :aIsSelected="daysStartAtMeridiem === 'am'"
              @selectionUpdate="onDaysStartAtMeridiemChange"
              class="meridiem-toggle"
            />
            <button
              class="save"
              type="button"
              v-if="daysStartAtHasChanged && daysStartAtIsValid"
              @click="updateDaysStartAt"
            >
              Save
            </button>
          </div>
          <p class="informative-text">
            (The daily allowances from any time limit commands will reset at
            this time. Also, if you set this to, for example, 1:30 am, a command
            that is active all day on Mondays will not become active until 1:30
            am every Monday and will remain active until 1:30 am of Tuesdays.)
          </p>
        </div>
      </section>
      <section>
        <h2>Account Settings</h2>
        <div class="block nested">
          <span class="block__label">Name:</span>
          <RegularTypeInput
            placeholder="Current Name"
            icon="pen"
            :widthMultiplier="1"
            v-model.trim="userName"
          />
          <button
            class="save"
            type="button"
            v-if="userNameHasChanged"
            @click="updateUserName"
          >
            Save
          </button>
        </div>
        <div class="block nested">
          <span class="block__label">Email:</span>
          <p class="guiding-text">{{ email }}</p>
        </div>
        <div class="block nested" v-show="false">
          <span class="block__label">Password:</span>
          <RegularTypeInput
            placeholder="(change password?)"
            icon="pen"
            :widthMultiplier="1"
            type="password"
          />
          <button class="save" v-show="false">Save</button>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import RegularTypeInput from "../elements/RegularTypeInput.vue";
import LabeledToggle from "../elements/LabeledToggle.vue";

export default {
  components: {
    RegularTypeInput,
    LabeledToggle,
  },

  data() {
    return {
      bossHaven: "",
      daysStartAtHours: "",
      daysStartAtMinutes: "",
      daysStartAtMeridiem: "",
      userName: "",
      email: "",
    };
  },

  computed: {
    hoursAreValid() {
      const hours = this.daysStartAtHours;

      if (hours === "") return false;
      if (isNaN(hours)) return false;
      if (hours > 12 || hours < 0) return false;

      return true;
    },
    minutesAreValid() {
      const minutes = this.daysStartAtMinutes;

      if (minutes === "") return false;
      if (isNaN(minutes)) return false;
      if (minutes > 59 || minutes < 0) return false;

      return true;
    },
    databaseFormattedHours() {
      if (!this.hoursAreValid) {
        return "invalid";
      }

      return this.formatHours(
        this.daysStartAtHours,
        "24-hour-format",
        "number",
        { position: this.daysStartAtMeridiem }
      );
    },
    databaseFormattedMinutes() {
      if (!this.minutesAreValid) {
        return "invalid";
      }
      return Number(this.daysStartAtMinutes);
    },
    savedBossHaven() {
      return this.$store.getters["settings/savedBossHaven"];
    },
    savedDaysStartAt() {
      return this.$store.getters["settings/savedDaysStartAt"];
    },
    savedUserName() {
      return this.$store.getters.userName;
    },
    savedEmail() {
      return this.$store.getters.email;
    },
    bossHavenHasChanged() {
      return this.bossHaven !== this.savedBossHaven;
    },
    daysStartAtHasChanged() {
      if (
        this.databaseFormattedHours !== this.savedDaysStartAt.hours ||
        this.databaseFormattedMinutes !== this.savedDaysStartAt.minutes
      ) {
        return true;
      }

      return false;
    },
    daysStartAtIsValid() {
      const hours = this.databaseFormattedHours;
      const minutes = this.databaseFormattedMinutes;

      if ([hours, minutes].includes("invalid")) return false;
      return true;
    },
    userNameHasChanged() {
      return this.userName !== this.savedUserName;
    },
    emailHasChanged() {
      return this.email !== this.savedEmail;
    },
  },

  methods: {
    formatHours(hours, targetFormat, type, meridiem) {
      if (targetFormat === "12-hour-format") {
        this[`${meridiem.target}Meridiem`] = hours < 12 ? "am" : "pm";

        if (hours > 12) {
          hours = hours - 12;
        }
      }

      // format netural, needed for both
      if (hours == 0) {
        hours = 12;
      }

      if (targetFormat === "24-hour-format") {
        if (meridiem.position === "pm" && hours != 12) {
          hours = hours + 12; // 12 pm will stay as 12
        } else if (meridiem.position === "am" && hours == 12) {
          hours = 0; // 12 am will become 0
        }
      }

      return type === "string" ? String(hours) : Number(hours);
    },
    formatMinutes(minutes, type) {
      if (minutes == 0 && type === "string") {
        return "00";
      } else {
        return type === "string" ? String(minutes) : Number(minutes);
      }
    },
    onDaysStartAtMeridiemChange(meridiem) {
      this.daysStartAtMeridiem = meridiem;
    },
    updateBossHaven() {
      this.$store.dispatch("settings/updateBossHaven", this.bossHaven);
    },
    updateDaysStartAt() {
      const hours = this.databaseFormattedHours;
      const minutes = this.databaseFormattedMinutes;

      if ([hours, minutes].includes("invalid")) return;

      const daysStartAt = {
        hours,
        minutes,
      };

      this.$store.dispatch("settings/updateDaysStartAt", daysStartAt);
    },
    updateUserName() {
      this.$store.dispatch("settings/updateUserName", this.userName);
    },
    initializeSettingsData() {},
  },
  async mounted() {
    await this.$store.dispatch("settings/fetchSettings");

    this.bossHaven = this.savedBossHaven;
    const daysStartAt = this.savedDaysStartAt;

    this.daysStartAtHours = this.formatHours(
      daysStartAt.hours,
      "12-hour-format",
      "string",
      { target: "daysStartAt" } // this sets the meridiem to am/pm
    );

    this.daysStartAtMinutes = this.formatMinutes(daysStartAt.minutes, "string");

    this.userName = this.savedUserName;
    this.email = this.savedEmail;
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

h2 {
  margin-bottom: 5rem;
}

.wrapper {
  overflow: auto;
  padding-right: 5rem;
  /*width*/
  &::-webkit-scrollbar {
    width: clamp(3px, 2.25rem, 8px);
  }
  /*track*/
  &::-webkit-scrollbar-track {
    background: colors.$grayFive;
    border-style: none;
    border-radius: 10rem;
  }
  /*thumb*/
  &::-webkit-scrollbar-thumb {
    background: colors.$auroraGradient;
    border-radius: 10rem;
  }
  /*thumb pressed*/
  &::-webkit-scrollbar-thumb:active {
    background: colors.$aquaGradient;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: colors.$grayThree colors.$grayFive;
  }
}

section {
  margin-bottom: 10rem;

  section:last-child {
    margin-bottom: 0;
  }
}

.nested {
  margin-left: 6rem;
}

.block {
  display: flex;
  align-items: center;
  margin-bottom: 8rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    @include type.bodyOne;
    margin-right: 5rem;
  }

  &.block--vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .guiding-text {
    @include type.bodyThree;
    color: colors.$grayTwo;
    margin-right: 2.5rem;

    &.colons {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .informative-text {
    @include type.bodyThree;
    color: colors.$grayThree;
    margin-top: 2.5rem;
    max-width: 70%;
  }

  .save {
    @include type.bodyTwo;
    line-height: 1em;
    background: colors.$auroraGradient;
    color: colors.$distantStar;
    padding: 2.5rem 4rem;
    border: none;
    border-radius: 4rem;
    margin-left: 5rem;
    cursor: pointer;
  }

  .meridiem-toggle {
    margin-left: 2.5rem;
  }
}
</style>
