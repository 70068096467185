<template>
  <div :class="['toggle', toggleOff]"></div>
</template>

<script>
export default {
  props: {
    isOn: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    toggleOff() {
      return { "toggle--off": !this.isOn };
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  $borderSize: 2px;
  $toggleHeight: 1em;
  $handleIncrement: 0.2em;
  $halfIncrement: calc(#{$handleIncrement} / 2);

  position: relative;
  font-size: inherit;
  height: $toggleHeight;
  width: calc(#{$toggleHeight} * 2);
  background: colors.$softPurple;
  margin: $halfIncrement $halfIncrement $halfIncrement 0;

  border: $borderSize solid colors.$brightTurquoise;
  border-radius: calc(#{$toggleHeight} * 3);
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    right: calc(-#{$borderSize} - #{$halfIncrement});
    top: calc(-#{$borderSize} - #{$halfIncrement});

    height: calc(#{$toggleHeight} + #{$handleIncrement});
    width: calc(#{$toggleHeight} + #{$handleIncrement});
    background: colors.$auroraGradient;
    border: $borderSize solid colors.$brightTurquoise;
    border-radius: 50%;
    transition: transform 0.35s ease-out;
  }

  &--off {
    background: colors.$grayFive;
    border-color: colors.$grayThree;
    margin: $halfIncrement 0 $halfIncrement $halfIncrement;

    &::before {
      top: calc(-#{$borderSize} - #{$halfIncrement});
      transform: translate(-$toggleHeight, 0%);
      background: colors.$grayFive;
      border-color: colors.$grayThree;
    }
  }
}
</style>
