<template>
  <li
    :class="['command', commandDisabled]"
    @click="onContextMenuOptionSelection('Edit')"
  >
    <div class="command__header">
      <div class="command__title">
        <RoundedToggle
          :isOn="isEnabled"
          @click.stop="$emit('toggleCommand', { commandId, index, isEnabled })"
        />
        <h3 class="command__name">{{ name }}</h3>
      </div>
      <div class="command__options">
        <img
          src="../../assets/icons/more-dots-sr.svg"
          alt="command options menu"
          class="command__options__dots"
          :ref="`optionsToggler${index}`"
        />
        <!-- @click.stop="contextMenuIsOpen = !contextMenuIsOpen" -->
        <EnclosedDropdown
          :options="['Edit', 'Delete']"
          :isOpen="contextMenuIsOpen"
          rightClosed="15%"
          top="80%"
          paddingTnB="1.5rem"
          paddingLnR="5rem"
          @optionSelected="onContextMenuOptionSelection"
          v-outclosable="dialogConfig"
          @click.stop=""
        />
      </div>
    </div>
    <div class="command__details">
      <p class="command__days">
        <span :class="isActiveMondays">Mo</span>
        <span :class="isActiveTuesdays">Tu</span>
        <span :class="isActiveWednesdays">We</span>
        <span :class="isActiveThursdays">Th</span>
        <span :class="isActiveFridays">Fr</span>
        <span :class="isActiveSaturdays">Sa</span>
        <span :class="isActiveSundays">Su</span>
      </p>
      <p class="command__time">
        <span>{{ timeTemplateA }}</span>
        <span class="user-time">{{ userTimeA }}</span>
        <span>{{ timeTemplateB }}</span>
        <span class="user-time">{{ userTimeB }}</span>
      </p>
      <p class="command__channels">
        <span class="channel-count">{{ sourcesCount }}</span>
        <span>channels</span>
      </p>
    </div>
  </li>
</template>

<script>
import RoundedToggle from "./RoundedToggle.vue";
import EnclosedDropdown from "./EnclosedDropdown.vue";

export default {
  components: {
    RoundedToggle,
    EnclosedDropdown,
  },

  emits: ["toggleCommand"],

  data() {
    return {
      contextMenuIsOpen: false,
      dialogConfig: {
        excludedElements: [`optionsToggler${this.index}`], // must be an array of ref names
        togglingController: `optionsToggler${this.index}`, // must be a ref name
        closureHandler: "closeContextMenu", // must be the name of a local method
        togglingHandler: "toggleContextMenu", // must be the name of a local method
        dialogInfo: {
          stateProperty: "contextMenuIsOpen", // must be the name of a data property holding the dialog state
          revert: true, // set this to true if state is false when dialog is to be opened
        },
      },
    };
  },

  computed: {
    commandDisabled() {
      return { "command--disabled": !this.isEnabled };
    },
    isActiveMondays() {
      return { "weekday--active": this.activeDays.includes("Mo") };
    },
    isActiveTuesdays() {
      return { "weekday--active": this.activeDays.includes("Tu") };
    },
    isActiveWednesdays() {
      return { "weekday--active": this.activeDays.includes("We") };
    },
    isActiveThursdays() {
      return { "weekday--active": this.activeDays.includes("Th") };
    },
    isActiveFridays() {
      return { "weekday--active": this.activeDays.includes("Fr") };
    },
    isActiveSaturdays() {
      return { "weekday--active": this.activeDays.includes("Sa") };
    },
    isActiveSundays() {
      return { "weekday--active": this.activeDays.includes("Su") };
    },
    timeTemplateA() {
      if (!this.type.includes("allowance")) return "from";
      return "daily limit of";
    },
    timeTemplateB() {
      if (!this.type.includes("allowance")) return "to";
      return "and";
    },
    userTimeA() {
      if (!this.type.includes("allowance")) {
        return this.formatInterval(this.interval.startTime);
      }
      return this.formatAllowance(this.dailyLimit, "hours");
    },
    userTimeB() {
      if (!this.type.includes("allowance")) {
        return this.formatInterval(this.interval.endTime);
      }
      return this.formatAllowance(this.dailyLimit, "minutes");
    },
  },

  methods: {
    formatInterval({ hours, minutes }) {
      const meridiem = hours < 12 ? "AM" : "PM";

      if (hours > 12) {
        hours = hours - 12;
      } else if (hours === 0) {
        hours = 12; // generates 12 AM instead of 0 AM
      }

      if (minutes === 0) {
        minutes = "00";
      }

      return `${hours}:${minutes} ${meridiem}`;
    },
    formatAllowance(minutesCap, timeUnit) {
      const hours = Math.floor(minutesCap / 60);

      if (timeUnit === "hours") {
        return `${hours}h`;
      } else {
        const minutes = minutesCap - hours * 60;
        return `${minutes}min`;
      }
    },
    onContextMenuOptionSelection(option) {
      this.contextMenuIsOpen = false;

      if (option === "Edit") {
        this.$store.dispatch("commands/setCommandToEdit", this.commandId);

        this.$store.dispatch("navigateToView", {
          view: "editCommand",
          component: "CommandSetup",
        });
      } else if (option === "Delete") {
        this.$store.dispatch("commands/deleteCommand", {
          commandId: this.commandId,
          platform: this.platform,
        });
      }
    },
    closeContextMenu() {
      this.contextMenuIsOpen = false;
    },
    toggleContextMenu() {
      this.contextMenuIsOpen = !this.contextMenuIsOpen;
    },
  },

  props: {
    commandId: { required: true },
    platform: { required: true },
    index: { type: Number },
    name: { type: String },
    type: { type: Array },
    sourcesCount: { type: Number },
    interval: { default: null },
    dailyLimit: { default: null },
    activeDays: { type: Array },
    isEnabled: { type: Boolean },
  },
};
</script>

<style lang="scss" scoped>
.command {
  border: 2px solid colors.$softPurple;
  padding: 3.45rem 6rem;
  margin-bottom: 3rem;
  @include type.bodyThree;
  border-radius: 6rem;
  cursor: pointer;
  transition: transform 0.25s ease-in;

  &:hover {
    transform: scale(0.994);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include type.bodyOne;
    display: flex;
    align-items: center;
  }

  &__name {
    @include type.bodyOne;
    line-height: 1em;
    margin-left: 3rem;
  }

  &__options {
    position: relative;
    padding: 0.5rem;
    display: flex;

    &__dots {
      width: 7rem;
      cursor: pointer;
    }
  }

  &__details {
    margin-top: 2rem;
    @include type.bodyThree;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__days {
    span {
      margin-right: 1.5rem;
    }

    .weekday--active {
      @include type.bodyOne;
    }
  }

  &__time {
    span {
      margin-right: 1.5rem;
    }

    .user-time {
      @include type.bodyOne;
    }
  }

  &__channels {
    color: colors.$brightTurquoise;

    .channel-count {
      @include type.bodyOne;
      margin-right: 1.5rem;
    }
  }
}

li.command.command--disabled {
  color: colors.$grayThree;
  border: 2px solid colors.$grayFour;

  .command__channels {
    color: colors.$grayThree;
  }
}
</style>
