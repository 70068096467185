<template>
  <li class="item" @click="toggleDetails">
    <div :class="['header', direction]">
      <div class="main-controls">
        <LabeledToggle
          optionA="Show"
          optionB="Hide"
          :valueA="false"
          :valueB="true"
          :aIsSelected="!itemIsHidden"
          @selectionUpdate="updateHiddenStatus"
          @click.stop=""
        />
        <h3 class="item-name">{{ itemName }}</h3>
      </div>
      <div class="secondary-controls">
        <img src="../../assets/icons/info-rr.svg" alt="info" />
        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          :class="dropdownIconClasses"
        >
          <path
            d="M6.34158 12.4915L12.8586 19.0085C13.0774 19.2272 13.3741 19.3501 13.6834 19.3501C13.9928 19.3501 14.2895 19.2272 14.5083 19.0085L21.0252 12.4915C21.1884 12.3283 21.2994 12.1205 21.3444 11.8942C21.3894 11.6679 21.3663 11.4334 21.278 11.2202C21.1898 11.0071 21.0403 10.8249 20.8485 10.6967C20.6566 10.5685 20.4311 10.5 20.2004 10.5H7.16642C6.93571 10.5 6.71019 10.5685 6.51838 10.6967C6.32657 10.8249 6.17708 11.0071 6.08879 11.2202C6.00051 11.4334 5.97741 11.6679 6.0224 11.8942C6.0674 12.1205 6.17847 12.3283 6.34158 12.4915Z"
          />
        </svg>
      </div>
    </div>
    <p class="description" v-show="detailsAreExpanded">{{ description }}</p>
  </li>
</template>

<script>
import LabeledToggle from "./LabeledToggle.vue";

export default {
  components: {
    LabeledToggle,
  },

  props: {
    itemName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    itemIsHidden: {
      type: Boolean,
      required: true,
    },
    index: {
      required: true,
    },
  },

  data() {
    return {
      detailsAreExpanded: false,
    };
  },

  computed: {
    direction() {
      return this.detailsAreExpanded ? "up" : "down";
    },
    dropdownIconClasses() {
      return {
        "dropdown-icon": true,
        "caret-up": this.detailsAreExpanded,
      };
    },
  },

  methods: {
    toggleDetails() {
      this.detailsAreExpanded = !this.detailsAreExpanded;
    },
    updateHiddenStatus(isHidden) {
      this.$store.dispatch("styling/updateHiddenStatus", {
        index: this.index,
        isHidden,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 3rem;
  border: 1px solid colors.$softPurple;
  border-radius: 6rem;
  margin-bottom: 3rem;
  cursor: pointer;
  transition: transform 0.25s ease-in;

  &:hover {
    transform: scale(0.994);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-controls {
    display: flex;
    align-items: center;

    .item-name {
      @include type.bodyOne;
      margin-left: 5rem;
      cursor: pointer;
    }
  }

  .secondary-controls {
    display: flex;
    align-items: center;
    cursor: pointer;

    .dropdown-icon {
      fill: colors.$distantStar;
      margin-left: 1.5rem;
      transition: all 0.3s ease-out;
      width: 6rem;

      &.caret-up {
        transform: rotate(-180deg) translate(-1px, -1px);
      }
    }
  }

  &.up {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid colors.$grayFive;
  }
}

.description {
  @include type.bodyThree;
  color: colors.$grayTwo;
  margin-top: 2.5rem;
}
</style>
