<template>
  <div :class="['signal', signal.type, visibleSignal]">
    <SignalIcon
      :icon="signal.icon"
      :signalType="signal.type"
      class="signal__icon"
    />
    <p class="signal__message">{{ signal.message }}</p>
  </div>
</template>

<script>
import SignalIcon from "./partials/SignalIcon.vue";

export default {
  components: {
    SignalIcon,
  },

  computed: {
    isVisible() {
      return this.$store.getters.aSignalIsVisible;
    },
    signal() {
      return this.$store.getters.pageSignal;
    },
    visibleSignal() {
      return {
        visible: this.isVisible,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.signal {
  position: absolute;
  padding: 2rem 6rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%) translateY(-10rem);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.visible {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }

  &.positive {
    border: 1px solid fades.$limeAccent;
    background: fades.$limeVoid;
    color: colors.$limeSignal;
  }

  &.caution {
    border: 1px solid fades.$peachAccent;
    background: fades.$peachVoid;
    color: colors.$peachSignal;
  }

  &.negative {
    border: 1px solid fades.$watermelonAccent;
    background: fades.$watermelonVoid;
    color: colors.$watermelonSignal;
  }

  &__icon {
    margin-right: 2rem;
  }

  &__message {
    @include type.bodyTwo;
  }
}
</style>
