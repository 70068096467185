<template>
  <ul :class="['dropdown', openStatus]" :style="dropdownStyles">
    <li
      class="option"
      :style="optionStyles"
      v-for="option in options"
      :key="option"
      @click="$emit('optionSelected', option)"
    >
      {{ option }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    rightClosed: {
      type: String,
      default: "0",
    },
    rightOpen: {
      type: String,
      default: "50%",
    },
    top: {
      type: String,
      default: "100%",
    },
    paddingTnB: {
      type: String,
      default: "2rem",
    },
    paddingLnR: {
      type: String,
      default: "4rem",
    },
  },

  computed: {
    openStatus() {
      return {
        open: this.isOpen,
      };
    },
    dropdownStyles() {
      const styles = {
        top: this.top,
      };

      if (this.isOpen) {
        styles.right = this.rightOpen;
      } else {
        styles.right = this.rightClosed;
      }

      return styles;
    },
    optionStyles() {
      return {
        padding: `${this.paddingTnB} ${this.paddingLnR}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  background: colors.$auroraGradient;
  overflow: hidden;
  color: colors.$distantStar;
  border-radius: 3rem 0 3rem 3rem;
  text-align: center;
  @include type.bodyThree;
  pointer-events: none;
  display: hidden;
  opacity: 0;
  transition: all 0.25s ease-out;

  &.open {
    opacity: 1;
    display: block;
    pointer-events: all;
  }

  .option {
    cursor: pointer;

    &:hover {
      background: fades.$gummyPurple;
      @include type.bodyTwo;
      font-weight: 700;
    }
  }
}
</style>
