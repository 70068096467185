<template>
  <ul>
    <EmojiExpression
      v-for="(emoji, index) in emojis"
      :key="emoji"
      :emoji="emoji"
      :selectedEmoji="selectedEmoji"
      :index="index"
      @updateState="onUpdateState"
    />
  </ul>
</template>

<script>
import EmojiExpression from "./EmojiExpression.vue";

export default {
  components: {
    EmojiExpression,
  },

  props: {
    selectedEmoji: {
      type: String,
      required: true,
      default: "",
    },
  },

  emits: ["satisfactionUpdate"],

  data() {
    return {
      emojis: ["saddest", "sad", "neutral", "happy", "happiest"],
      activeEmoji: "",
    };
  },

  methods: {
    onUpdateState(index) {
      if (this.activeEmoji === this.emojis[index]) {
        this.activeEmoji = "";
      } else {
        this.activeEmoji = this.emojis[index];
      }

      this.$emit("satisfactionUpdate", this.activeEmoji);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
}
</style>
