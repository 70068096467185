export default {
  namespaced: true,
  state() {
    return {
      hideableItems: [
        {
          id: "homeFeed",
          name: "Home Feed",
          description:
            "Refers to the video recommendations that appear in YouTube's homepage.",
          isCommon: true,
          isHidden: false,
        },
        {
          id: "relatedVideos",
          name: "Related Videos",
          description:
            "Refers to the video recommendations that appear next to a video you are watching.",
          isCommon: true,
          isHidden: false,
        },
        {
          id: "videoEndRecommendations",
          name: "Video-End Recommendations",
          description:
            "Refers to the video recommendations that appear after a video you are watching ends.",
          isCommon: true,
          isHidden: false,
        },
        {
          id: "notificationCountBadge",
          name: "Notification Count Badge",
          description:
            "Refers to the red badge next to the notifications icon. Hiding this will also hide the notification count text YouTube adds to tab titles.",
          isCommon: true,
          isHidden: false,
        },
        {
          id: "notificationsBell",
          name: "Notifications Bell",
          description:
            "Refers to the notifications icon. Hiding this will also hide the Notification Count Badge.",
          isCommon: false,
          isHidden: false,
        },
        {
          id: "exploreTab",
          name: "Explore Tab",
          description: `Refers to the "Explore" button in YouTube's left sidebar.`,
          isCommon: true,
          isHidden: false,
        },
        {
          id: "subscriptionsTab",
          name: "Subscriptions Tab",
          description: `Refers to the "Subscriptions" button in YouTube's left sidebar.`,
          isCommon: false,
          isHidden: false,
        },
        {
          id: "subscriptionsSection",
          name: "Subscriptions Section",
          description:
            "Refers to the section in YouTube's left sidebar listing the chanels you're subscribed to which are live now or have updates.",
          isCommon: true,
          isHidden: false,
        },
        {
          id: "moreFromYouTubeSection",
          name: "More from YouTube Section",
          description: `Refers to the section in YouTube's left sidebar with shortcuts to categories like "YouTube Premium and "Gaming".`,
          isCommon: true,
          isHidden: false,
        },
        {
          id: "sidebarFooter",
          name: "Sidebar Footer",
          description: `Refers to all the secondary links in YouTube's left sidebar to pages like "Press", "Copyright", "Terms", etc.`,
          isCommon: true,
          isHidden: false,
        },
        {
          id: "commentsSection",
          name: "Comments Section",
          description:
            "Refers to the comments section below every video you watch.",
          isCommon: false,
          isHidden: false,
        },
        {
          id: "libraryTab",
          name: "Library Tab",
          description: `Refers to the "Library" button in YouTube's left sidebar.`,
          isCommon: false,
          isHidden: false,
        },
        {
          id: "historyTab",
          name: "History Tab",
          description: `Refers to the "History" button in YouTube's left sidebar.`,
          isCommon: false,
          isHidden: false,
        },
        {
          id: "likedVideosTab",
          name: "Liked Videos Tab",
          description: `Refers to the "Liked Videos" button in YouTube's left sidebar.`,
          isCommon: false,
          isHidden: false,
        },
      ],
      isFetchingItemStatuses: false,
      lastStylingChange: null,
    };
  },
  getters: {
    hideableItems(state) {
      return state.hideableItems;
    },
    mostCommonAreHidden(state) {
      const hideableItems = state.hideableItems;

      for (const item of hideableItems) {
        if (item.isCommon && !item.isHidden) {
          return false;
        }
      }

      return true;
    },
    isFetchingItemStatuses(state) {
      return state.isFetchingItemStatuses;
    },
    notificationsBellIsHidden(state) {
      const notificationsBell = state.hideableItems.find(
        (item) => item.id === "notificationsBell"
      );
      return notificationsBell.isHidden;
    },
    lastStylingChange(state) {
      return state.lastStylingChange;
    },
  },
  mutations: {
    setFetchingStatus(state, status) {
      state.isFetchingItemStatuses = status;
    },
    setItemsVisibility(state, itemsVisiblity) {
      for (let itemId in itemsVisiblity) {
        state.hideableItems.find((item) => item.id === itemId).isHidden =
          itemsVisiblity[itemId];
      }
    },
    setHiddenStatus(state, { id, isHidden }) {
      state.hideableItems.find((item) => item.id === id).isHidden = isHidden;
    },
    updateLastStylingChange(state) {
      state.lastStylingChange = Date.now();
    },
  },
  actions: {
    async fetchHideableItems(context) {
      context.commit("setFetchingStatus", true);

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/hideableItems/${userId}/YouTube.json?auth=${token}`
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      const allItems = await response.json();
      context.commit("setItemsVisibility", allItems);

      context.commit("setFetchingStatus", false);
    },
    async updateHiddenStatus(context, { index, isHidden }) {
      // check if the visibility status has changed
      const targetedItem = context.getters.hideableItems[index];
      const wasHidden = targetedItem.isHidden;
      if (wasHidden === isHidden) return;

      // otherwise, update the status in the database and refetch

      // first, execute the change locally immediately
      context.commit("setHiddenStatus", { id: targetedItem.id, isHidden });

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const updatedStatus = {
        [targetedItem.id]: isHidden,
      };

      // hiding the notifications bell also hides the count badge
      if (targetedItem.id === "notificationsBell" && isHidden) {
        updatedStatus.notificationCountBadge = true;
        context.commit("setHiddenStatus", {
          id: "notificationCountBadge",
          isHidden,
        });
      }

      // not allowed to show notification count badge if notifications bell is hidden
      if (
        targetedItem.id === "notificationCountBadge" &&
        !isHidden &&
        context.getters.notificationsBellIsHidden
      ) {
        // undo previous toggling to reflect the change is invalid
        context.commit("setHiddenStatus", {
          id: targetedItem.id,
          isHidden: !isHidden,
        });
        return;
      }

      const response = await fetch(
        `${databaseURL}/hideableItems/${userId}/YouTube.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify(updatedStatus),
        }
      );

      if (!response.ok) {
        console.log(response);

        // reverse the change to show that the request didn't go through
        context.commit("setHiddenStatus", { index, isHidden: !isHidden });
        return;
      }

      context.commit("updateLastStylingChange");
    },
    async hideMostCommon(context) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const updatedStatuses = {};

      for (const item of context.getters.hideableItems) {
        if (item.isCommon) {
          updatedStatuses[item.id] = true; // true means it's hidden
        }
      }

      const response = await fetch(
        `${databaseURL}/hideableItems/${userId}/YouTube.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify(updatedStatuses),
        }
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      context.commit("updateLastStylingChange");
      context.dispatch("fetchHideableItems");
    },
  },
};
