<template>
  <nav ref="sidebar">
    <img
      src="../../assets/icons/tb-logo.svg"
      alt="Time BOSS logo"
      class="logo"
    />
    <nav class="menu">
      <ul>
        <li
          v-for="page in pages"
          :key="page.icon"
          @click="navigateToPage(page.name, page.view, page.defaultComponent)"
        >
          <div
            :class="['circle', { 'circle--active': activePage === page.name }]"
          >
            <MenuIcon
              :icon="page.icon"
              :size="iconSize"
              :selected="activePage === page.name"
            />
          </div>
          <img
            src="../../assets/icons/page-marker.svg"
            alt="page marker"
            :class="['marker', { 'marker--active': activePage === page.name }]"
          />
        </li>
      </ul>
      <div class="menu__background"></div>
    </nav>
  </nav>
</template>

<script>
import MenuIcon from "../elements/MenuIcon.vue";

export default {
  components: {
    MenuIcon,
  },

  data() {
    return {
      pages: [
        {
          name: "commands",
          icon: "commandsIcon",
          view: "commandsList",
          defaultComponent: "CommandsList",
        },
        {
          name: "styling",
          icon: "stylingIcon",
          view: "stylingPage",
          defaultComponent: "HideableItems",
        },
        {
          name: "feedback",
          icon: "feedbackIcon",
          view: "feedbackPage",
          defaultComponent: "UserFeedback",
        },
        {
          name: "settings",
          icon: "settingsIcon",
          view: "settingsPage",
          defaultComponent: "SettingsPage",
        },
      ],
      iconSize: "clamp(22px, 1.5vw, 45px)",
    };
  },

  computed: {
    activePage() {
      const currentView = this.$store.getters.currentView;

      switch (currentView) {
        case "commandsList":
        case "newCommand":
        case "editCommand":
          return "commands";
        case "stylingPage":
          return "styling";
        case "feedbackPage":
          return "feedback";
        case "settingsPage":
          return "settings";
        default:
          return "commands";
      }
    },
    commandsIcon() {
      if (this.activePage === "commands") return "commands-sr";
      return "commands-rr";
    },
    stylingIcon() {
      if (this.activePage === "styling") return "styling-sr";
      return "styling-rr";
    },
    feedbackIcon() {
      if (this.activePage === "feedback") return "feedback-sr";
      return "feedback-rr";
    },
    settingsIcon() {
      if (this.activePage === "settings") return "settings-sr";
      return "settings-rr";
    },
  },

  methods: {
    logRef() {
      console.log(this.$refs.sidebar.offsetWidth + "px");
    },
    navigateToPage(pageName, pageView, pageComponent) {
      if (this.activePage === pageName) {
        return;
      }

      this.$store.dispatch("navigateToView", {
        view: pageView,
        component: pageComponent,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: clamp(64px, 6vw, 120px);
  grid-area: 1 / 1 / 2 / 2;
}

.menu {
  $bgWidth: calc(clamp(44px, 3vw, 75px) + (1.25vh * 2));
  //...the value given by clamp is the circle size, 1.25 the padding

  grid-area: 1 / 1 / 3 / 2;
  width: 100%;
  position: relative;

  &__background {
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: $bgWidth;
    left: 50%;
    transform: translateX(-50%);
    background: fades.$purpleGlass;
    border-radius: 8rem;
    box-shadow: shadows.$subtleDarkness;
  }
}

ul {
  display: grid;
  padding: 2.5vh 0;
  grid-template-columns: 1fr;
  row-gap: clamp(10px, 1.3vh, 75px);
  justify-content: center;
}

li {
  position: relative;
  display: flex;
  justify-content: center;
}

.marker {
  position: absolute;
  z-index: 1;
  height: 200%;
  top: -50%;
  right: 0;
  width: 0;
  transition: width 0.2s ease-in-out;

  &--active {
    width: 65%;
  }
}

.circle {
  background: colors.$auroraGradient;
  display: inline-block;
  height: clamp(44px, 3vw, 75px);
  width: clamp(44px, 3vw, 75px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;

  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  &--active {
    background: colors.$aquaGradient;
  }
}
</style>
