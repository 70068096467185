<template>
  <header>
    <h1>{{ templates[currentView].pageTitle }}</h1>
    <div class="details" v-if="templates[currentView].pageDescription">
      <p>{{ templates[currentView].pageDescription }}</p>
      <primary-button
        :icon="templates[currentView].cta.icon"
        v-if="ctaIsVisible"
        @click="executePrimaryAction"
      >
        {{ templates[currentView].cta.label }}
      </primary-button>
    </div>
  </header>
</template>

<script>
import PrimaryButton from "../elements/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  computed: {
    currentView() {
      return this.$store.getters.currentView;
    },
    ctaIsVisible() {
      switch (this.currentView) {
        case "newCommand":
        case "editCommand":
        case "settingsPage":
          return false;
        case "stylingPage":
          return !this.$store.getters["styling/mostCommonAreHidden"];
        default:
          return true;
      }
    },
  },

  data() {
    return {
      templates: {
        commandsList: {
          pageTitle: "Commands",
          pageDescription:
            "You are the boss. Give a command, and YouTube will obey.",
          cta: {
            label: "New command",
            icon: "plusIcon",
          },
        },
        newCommand: {
          pageTitle: "New Command",
          pageDescription:
            "Tell YouTube what channels are allowed and when. You can even define time limits. Your word is law.",
          cta: null,
        },
        editCommand: {
          pageTitle: "Edit Command",
          pageDescription:
            "Tell YouTube what channels are allowed and when. You can even define time limits. Your word is law.",
          cta: null,
        },
        stylingPage: {
          pageTitle: "Styling",
          pageDescription:
            "Hide the elements of YouTube that distract you. You don't have to resist temptations if you aren't exposed to them.",
          cta: {
            label: "Hide most common",
            icon: "hiddenIcon",
          },
        },
        feedbackPage: {
          pageTitle: "Feedback",
          pageDescription:
            "We love listening to our users and learning from their experiences. Share your feedback to help us improve.",
          cta: {
            label: "Book a call with CEO",
            icon: "calendarIcon",
          },
        },
        settingsPage: {
          pageTitle: "Settings",
          pageDescription: null,
          cta: null,
        },
      },
    };
  },

  methods: {
    executePrimaryAction() {
      switch (this.currentView) {
        case "commandsList":
          this.$store.dispatch("navigateToView", {
            view: "newCommand",
            component: "CommandSetup",
          });
          break;
        case "stylingPage":
          this.$store.dispatch("styling/hideMostCommon");
          break;
        case "feedbackPage":
          window.open(
            "https://calendly.com/mares_zhar/chat-with-time-boss-founder",
            "_blank"
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding-bottom: 10rem;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2rem;
}

p {
  padding-top: 6rem;
  line-height: 1em;
  color: colors.$grayOne;
}
</style>
