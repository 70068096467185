<template>
  <svg
    v-if="icon === 'tickMark'"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    :class="signalType"
  >
    <path
      d="M1.26524 12.5681L6.12558 17.4284C6.78192 18.0846 7.672 18.4532 8.60008 18.4532C9.52815 18.4532 10.4182 18.0846 11.0746 17.4284L26.0849 2.41808C26.2974 2.19805 26.415 1.90334 26.4124 1.59745C26.4097 1.29155 26.287 0.998936 26.0707 0.782627C25.8544 0.566317 25.5618 0.44362 25.2559 0.440962C24.95 0.438304 24.6553 0.555897 24.4352 0.768415L9.42491 15.7787C9.20613 15.9975 8.90943 16.1203 8.60008 16.1203C8.29072 16.1203 7.99402 15.9975 7.77524 15.7787L2.91491 10.9184C2.69487 10.7059 2.40017 10.5883 2.09427 10.591C1.78838 10.5936 1.49576 10.7163 1.27945 10.9326C1.06314 11.1489 0.940446 11.4416 0.937788 11.7474C0.93513 12.0533 1.05272 12.348 1.26524 12.5681Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    size: { type: String, default: "5.5rem" },
    signalType: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.positive {
  fill: colors.$limeSignal;
}

.caution {
  fill: colors.$peachSignal;
}

.negative {
  fill: colors.$watermelonSignal;
}
</style>
