<template>
  <li :class="['circle', isActive]" @click="toggleStatus">
    {{ label }}
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    isOn: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  emits: ["statusUpdate"],

  computed: {
    isActive() {
      return {
        active: this.isOn,
      };
    },
  },

  methods: {
    toggleStatus() {
      this.$emit("statusUpdate", {
        value: this.label,
        isActive: !this.isOn,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  background: colors.$graySix;
  height: 11rem;
  width: 11rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @include type.bodyThree;
  color: colors.$grayTwo;
  cursor: pointer;

  &.active {
    background: colors.$softPurple;
    @include type.bodyTwo;
    color: colors.$distantStar;
  }
}
</style>
