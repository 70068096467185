<template>
  <div
    :class="['dropdown', expanded]"
    :style="dynamicWidth"
    @mouseover="isExpanded = true"
    @mouseleave="isExpanded = false"
  >
    <div class="prompt" @click="isExpanded = !isExpanded">
      <span>{{ label }}</span>
      <svg
        :width="size"
        :height="size"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.34158 12.4915L12.8586 19.0085C13.0774 19.2272 13.3741 19.3501 13.6834 19.3501C13.9928 19.3501 14.2895 19.2272 14.5083 19.0085L21.0252 12.4915C21.1884 12.3283 21.2994 12.1205 21.3444 11.8942C21.3894 11.6679 21.3663 11.4334 21.278 11.2202C21.1898 11.0071 21.0403 10.8249 20.8485 10.6967C20.6566 10.5685 20.4311 10.5 20.2004 10.5H7.16642C6.93571 10.5 6.71019 10.5685 6.51838 10.6967C6.32657 10.8249 6.17708 11.0071 6.08879 11.2202C6.00051 11.4334 5.97741 11.6679 6.0224 11.8942C6.0674 12.1205 6.17847 12.3283 6.34158 12.4915Z"
        />
      </svg>
    </div>
    <ul class="options" :style="dynamicWidth">
      <li
        class="option"
        v-for="option in availableOptions"
        :key="option"
        @click="selectOption(option)"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "(choose an option)",
    },
    options: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: "5rem",
    },
    widthMultiplier: {
      type: Number,
      default: 1,
    },
    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  methods: {
    selectOption(option) {
      this.isExpanded = false;
      this.$emit("optionSelection", option);
    },
  },

  computed: {
    expanded() {
      return {
        expanded: this.isExpanded,
      };
    },

    dynamicWidth() {
      let longestText = this.label.length;

      this.options.forEach((option) => {
        if (option.length > longestText) {
          longestText = option.length;
        }
      });

      return {
        width: longestText * this.widthMultiplier + "ch",
      };
    },

    label() {
      if (!this.value) return this.placeholder;
      return this.value;
    },

    availableOptions() {
      if (this.options.includes(this.label)) {
        return [this.placeholder, ...this.options];
      } else {
        return this.options;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  @include type.bodyThree;
  color: colors.$brightTurquoise;
  cursor: pointer;

  .prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem 1rem;
    border-bottom: 1px solid colors.$brightTurquoise;
  }

  &.expanded {
    svg {
      transform: rotate(-180deg) translate(-1px, -1px);
    }

    .options {
      display: block;
    }
  }

  .options {
    background: colors.$auroraGradient;
    color: colors.$distantStar;

    border-radius: 0 0 3rem 3rem;
    text-align: center;
    position: absolute;
    z-index: 1;
    display: none;
    box-shadow: shadows.$jewel;

    .option {
      padding: 2rem 4rem;
      cursor: pointer;

      &:hover {
        background: fades.$gummyPurple;
        @include type.bodyTwo;
        font-weight: 700;
        overflow: none;
      }

      &:last-child {
        margin-bottom: 0;
        border-radius: 0 0 3rem 3rem;
      }
    }
  }
}

svg {
  fill: colors.$brightTurquoise;
  margin-left: 1rem;
  transition: all 0.3s ease-out;
}
</style>
