<template>
  <div @click="$emit('submitInput')">
    <svg
      v-if="icon === 'plusSign'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11H13V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11Z"
      />
    </svg>

    <svg
      v-else-if="icon === 'caretDown'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43564 10.2785L11.0216 15.8645C11.2092 16.052 11.4635 16.1573 11.7286 16.1573C11.9938 16.1573 12.2481 16.052 12.4356 15.8645L18.0216 10.2785C18.1615 10.1387 18.2567 9.96052 18.2952 9.76656C18.3338 9.57261 18.314 9.37158 18.2383 9.18888C18.1627 9.00618 18.0345 8.85002 17.8701 8.74013C17.7057 8.63025 17.5124 8.57158 17.3146 8.57153H6.14264C5.94489 8.57158 5.7516 8.63025 5.58719 8.74013C5.42278 8.85002 5.29464 9.00618 5.21897 9.18888C5.1433 9.37158 5.12349 9.57261 5.16206 9.76656C5.20063 9.96052 5.29583 10.1387 5.43564 10.2785Z"
      />
    </svg>

    <svg
      v-else-if="icon === 'link'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5808 16.5147L10.7848 19.3107C9.97304 20.1007 8.88283 20.5395 7.75006 20.5319C6.61729 20.5243 5.53306 20.071 4.73194 19.2701C3.93082 18.4692 3.47724 17.3851 3.46933 16.2523C3.46142 15.1196 3.89982 14.0292 4.68968 13.2172L7.48568 10.4187C7.6464 10.2578 7.73664 10.0397 7.73656 9.81236C7.73648 9.58499 7.64608 9.36696 7.48525 9.20623C7.32441 9.04551 7.10632 8.95527 6.87894 8.95535C6.65157 8.95543 6.43354 9.04583 6.27282 9.20666L3.47768 12.0052C2.34819 13.1353 1.71388 14.6677 1.71429 16.2655C1.71469 17.8632 2.34977 19.3953 3.47982 20.5248C4.60987 21.6543 6.14232 22.2886 7.74005 22.2882C9.33778 22.2878 10.8699 21.6527 11.9994 20.5227L14.7954 17.7267C14.9515 17.565 15.0379 17.3485 15.036 17.1237C15.034 16.899 14.9439 16.684 14.7849 16.5251C14.626 16.3662 14.411 16.276 14.1863 16.2741C13.9616 16.2721 13.745 16.3585 13.5834 16.5147H13.5808ZM20.5228 3.48095C19.9651 2.91936 19.3014 2.47404 18.5703 2.1708C17.8392 1.86756 17.0552 1.71242 16.2637 1.71438C15.4726 1.71228 14.6889 1.86701 13.958 2.16965C13.2271 2.47228 12.5634 2.91681 12.0054 3.47752L9.2051 6.27438C9.04427 6.4351 8.95387 6.65313 8.95379 6.8805C8.95371 7.10788 9.04395 7.32597 9.20467 7.48681C9.3654 7.64764 9.58343 7.73804 9.8108 7.73812C10.0382 7.7382 10.2563 7.64796 10.4171 7.48723L13.2157 4.69123C13.6147 4.28979 14.0894 3.97149 14.6123 3.75477C15.1352 3.53805 15.6959 3.42721 16.262 3.42866C17.1142 3.42894 17.9472 3.68189 18.6557 4.15552C19.3642 4.62915 19.9163 5.3022 20.2424 6.08959C20.5684 6.87699 20.6537 7.74337 20.4874 8.57921C20.3212 9.41506 19.9108 10.1828 19.3082 10.7855L16.5122 13.5815C16.3514 13.7424 16.2611 13.9605 16.2611 14.1879C16.2611 14.4154 16.3514 14.6335 16.5122 14.7944C16.6731 14.9552 16.8912 15.0456 17.1187 15.0456C17.3461 15.0456 17.5643 14.9552 17.7251 14.7944L20.5211 12.0001C21.6491 10.8696 22.2828 9.33789 22.2831 7.74087C22.2834 6.14385 21.6504 4.61189 20.5228 3.48095V3.48095Z"
      />
      <path
        d="M13.9648 8.82264L8.82191 13.9655C8.74004 14.0446 8.67474 14.1391 8.62982 14.2437C8.5849 14.3483 8.56125 14.4608 8.56027 14.5746C8.55928 14.6884 8.58096 14.8013 8.62406 14.9066C8.66716 15.0119 8.73081 15.1076 8.81128 15.1881C8.89176 15.2686 8.98747 15.3322 9.09281 15.3753C9.19814 15.4184 9.31101 15.4401 9.42482 15.4391C9.53863 15.4382 9.65111 15.4145 9.75568 15.3696C9.86026 15.3247 9.95484 15.2594 10.0339 15.1775L15.1768 10.0346C15.3329 9.87298 15.4193 9.65647 15.4173 9.43173C15.4154 9.20699 15.3252 8.992 15.1663 8.83308C15.0074 8.67416 14.7924 8.58402 14.5677 8.58206C14.3429 8.58011 14.1264 8.66651 13.9648 8.82264Z"
      />
    </svg>

    <svg
      v-else-if="icon === 'pen'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.593 3.40748C20.0569 2.87219 19.3303 2.57153 18.5727 2.57153C17.8151 2.57153 17.0884 2.87219 16.5523 3.40748L3.72672 16.2331C3.35944 16.5983 3.06822 17.0327 2.86992 17.5112C2.67162 17.9897 2.57017 18.5028 2.57144 19.0207V20.6397C2.57144 20.8488 2.65452 21.0494 2.80241 21.1973C2.9503 21.3452 3.15088 21.4283 3.36003 21.4283H4.979C5.49691 21.4297 6.00997 21.3284 6.48847 21.1303C6.96698 20.9321 7.40142 20.641 7.76665 20.2738L20.593 7.44742C21.1281 6.91133 21.4286 6.18486 21.4286 5.42745C21.4286 4.67004 21.1281 3.94357 20.593 3.40748V3.40748ZM6.65159 19.1587C6.20683 19.6005 5.60589 19.8493 4.979 19.8511H4.14861V19.0207C4.14782 18.7099 4.20867 18.4021 4.32765 18.115C4.44664 17.8279 4.62138 17.5672 4.84178 17.3481L14.5753 7.6146L16.3891 9.42835L6.65159 19.1587ZM19.4772 6.33235L17.501 8.30934L15.6872 6.49953L17.6642 4.52255C17.9047 4.28255 18.2307 4.14793 18.5705 4.1483C18.9103 4.14867 19.236 4.284 19.476 4.52452C19.716 4.76504 19.8506 5.09104 19.8502 5.43082C19.8499 5.77059 19.7145 6.0963 19.474 6.3363L19.4772 6.33235Z"
      />
    </svg>

    <svg
      v-else-if="icon === 'closedLock'"
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.93493V7.71435C18 6.12306 17.3679 4.59693 16.2426 3.47171C15.1174 2.3465 13.5913 1.71436 12 1.71436C10.4087 1.71436 8.88258 2.3465 7.75736 3.47171C6.63214 4.59693 6 6.12306 6 7.71435V8.93493C5.23659 9.2681 4.58682 9.81652 4.13014 10.5131C3.67346 11.2097 3.42967 12.0243 3.42857 12.8572V18.0001C3.42993 19.1363 3.8819 20.2256 4.68533 21.029C5.48876 21.8325 6.57806 22.2844 7.71429 22.2858H16.2857C17.4219 22.2844 18.5112 21.8325 19.3147 21.029C20.1181 20.2256 20.5701 19.1363 20.5714 18.0001V12.8572C20.5703 12.0243 20.3265 11.2097 19.8699 10.5131C19.4132 9.81652 18.7634 9.2681 18 8.93493V8.93493ZM7.71429 7.71435C7.71429 6.57771 8.16582 5.48762 8.96954 4.6839C9.77327 3.88017 10.8634 3.42864 12 3.42864C13.1366 3.42864 14.2267 3.88017 15.0305 4.6839C15.8342 5.48762 16.2857 6.57771 16.2857 7.71435V8.5715H7.71429V7.71435ZM18.8571 18.0001C18.8571 18.6821 18.5862 19.3361 18.104 19.8183C17.6218 20.3006 16.9677 20.5715 16.2857 20.5715H7.71429C7.0323 20.5715 6.37825 20.3006 5.89601 19.8183C5.41377 19.3361 5.14286 18.6821 5.14286 18.0001V12.8572C5.14286 12.1752 5.41377 11.5212 5.89601 11.0389C6.37825 10.5567 7.0323 10.2858 7.71429 10.2858H16.2857C16.9677 10.2858 17.6218 10.5567 18.104 11.0389C18.5862 11.5212 18.8571 12.1752 18.8571 12.8572V18.0001Z"
      />
      <path
        d="M12 13.7144C11.7727 13.7144 11.5547 13.8047 11.3939 13.9654C11.2332 14.1262 11.1429 14.3442 11.1429 14.5715V16.2858C11.1429 16.5131 11.2332 16.7311 11.3939 16.8919C11.5547 17.0526 11.7727 17.1429 12 17.1429C12.2273 17.1429 12.4453 17.0526 12.6061 16.8919C12.7668 16.7311 12.8571 16.5131 12.8571 16.2858V14.5715C12.8571 14.3442 12.7668 14.1262 12.6061 13.9654C12.4453 13.8047 12.2273 13.7144 12 13.7144Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    size: { type: String, default: "6rem" },
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: colors.$brightTurquoise;
  margin-bottom: -1rem;
}

div {
  margin-left: 2rem;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
