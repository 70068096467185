<template>
  <li @click="$emit('updateState', index)">
    <img
      :src="require(`../../assets/icons/emoji-${iconName}.svg`)"
      :alt="iconName"
    />
  </li>
</template>

<script>
export default {
  props: {
    emoji: {
      type: String,
      required: true,
    },
    selectedEmoji: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  emits: ["updateState"],

  computed: {
    isSelected() {
      return this.emoji === this.selectedEmoji;
    },
    iconName() {
      const ending = this.isSelected ? "-on" : "-off";
      return this.emoji + ending;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  cursor: pointer;
}

img {
  height: 24rem;
  width: 24rem;

  // Not adding this at least for now because the shadow is clipped by
  // an outer layer.
  // &.shadow {
  //   box-shadow: shadows.$spell;
  // }
}
</style>
