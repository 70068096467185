<template>
  <main>
    <h2>My Feedback</h2>
    <section>
      <form @submit.prevent>
        <div class="block">
          <span class="block__label">Satisfaction with Time BOSS:</span>
          <SatisfactionExpressions
            :selectedEmoji="satisfactionLevel"
            @satisfactionUpdate="onSatisfactionUpdate"
          />
        </div>
        <div class="block">
          <span class="block__label">Before Time BOSS:</span>
          <div class="prompt">
            <p class="guiding-text">
              The amount of time I spent on YouTube was
            </p>
            <BasicDropdown
              :options="usagePositions"
              @optionSelection="updateTimeSpentBefore"
              :value="timeSpentBefore"
            />
          </div>
        </div>
        <div class="block">
          <span class="block__label">After Using Time BOSS:</span>
          <div class="prompt">
            <p class="guiding-text">
              The amount of time I spend on YouTube now is
            </p>
            <BasicDropdown
              :options="usagePositions"
              @optionSelection="updateTimeSpentAfter"
              :value="timeSpentAfter"
            />
          </div>
        </div>
        <div class="block block--vertical block--last">
          <span class="block__label">Open Feedback:</span>
          <textarea
            class="open-feedback"
            cols="76"
            rows="4"
            placeholder="Use this space to share anything else you'd like to tell us."
            v-model.trim="openFeedback"
          ></textarea>
          <div class="submit-row">
            <button
              :class="['submit-button', buttonIsEnabled]"
              type="button"
              @click="submitFeedback"
            >
              Submit Feedback
            </button>
            <p class="last-submission__intro" v-if="latestSubmissionDate">
              Last Feedback Update:
              <span class="last-submission__date">
                {{ latestSubmissionDate }}
              </span>
            </p>
          </div>
        </div>
      </form>
    </section>
  </main>
</template>

<script>
import SatisfactionExpressions from "../elements/SatisfactionExpressions.vue";
import BasicDropdown from "../elements/BasicDropdown.vue";

export default {
  components: {
    SatisfactionExpressions,
    BasicDropdown,
  },

  data() {
    return {
      satisfactionLevel: "",
      usagePositions: [
        "way more than ideal",
        "more than ideal",
        "ideal",
        "less than ideal",
        "way less than ideal",
      ],
      timeSpentBefore: "",
      timeSpentAfter: "",
      openFeedback: "",
    };
  },

  computed: {
    feedbackHasEnoughInfo() {
      if (
        this.satisfactionLevel ||
        this.timeSpentBeforeHasInfo ||
        this.timeSpentAfterHasInfo ||
        this.openFeedback
      ) {
        return true;
      }
      return false;
    },
    buttonIsEnabled() {
      return {
        "button-enabled": this.feedbackHasEnoughInfo,
      };
    },
    timeSpentBeforeHasInfo() {
      return (
        this.timeSpentBefore !== "" &&
        this.timeSpentBefore !== "(choose an option)"
      );
    },
    timeSpentAfterHasInfo() {
      return (
        this.timeSpentAfter !== "" &&
        this.timeSpentAfter !== "(choose an option)"
      );
    },
    latestSubmissionDate() {
      return this.$store.getters["feedback/latestSubmissionDate"];
    },
  },

  methods: {
    onSatisfactionUpdate(satisfaction) {
      this.satisfactionLevel = satisfaction;
    },
    updateTimeSpentBefore(option) {
      this.timeSpentBefore = option;
    },
    updateTimeSpentAfter(option) {
      this.timeSpentAfter = option;
    },
    submitFeedback() {
      if (!this.feedbackHasEnoughInfo) return;

      const feedback = {
        associatedPlatforms: ["YouTube"],
        dateSubmitted: `${new Date()}`,
        associatedEmail: this.$store.getters.email,
        associatedName: this.$store.getters.userName,
      };

      if (this.satisfactionLevel) {
        feedback.overallSatisfaction = this.satisfactionLevel;
      }

      if (this.timeSpentBeforeHasInfo) {
        feedback.previousTimeExpenditure = this.timeSpentBefore;
      }

      if (this.timeSpentAfterHasInfo) {
        feedback.currentTimeExpenditure = this.timeSpentAfter;
      }

      if (this.openFeedback) {
        feedback.openFeedback = this.openFeedback;
      }

      this.$store.dispatch("feedback/submitFeedback", feedback);
      this.thankUserAndClearInputs();
    },
    thankUserAndClearInputs() {
      this.satisfactionLevel = "";
      this.timeSpentBefore = "";
      this.timeSpentAfter = "";
      this.openFeedback = "";
    },
  },

  mounted() {
    this.$store.dispatch("feedback/fetchLatestSubmissionDate");
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

h2 {
  margin-bottom: 5rem;
}

section {
  overflow: auto;
  padding-right: 5rem;

  /*width*/
  &::-webkit-scrollbar {
    width: clamp(3px, 2.25rem, 8px);
  }
  /*track*/
  &::-webkit-scrollbar-track {
    background: colors.$grayFive;
    border-style: none;
    border-radius: 10rem;
  }
  /*thumb*/
  &::-webkit-scrollbar-thumb {
    background: colors.$auroraGradient;
    border-radius: 10rem;
  }
  /*thumb pressed*/
  &::-webkit-scrollbar-thumb:active {
    background: colors.$aquaGradient;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: colors.$grayThree colors.$grayFive;
  }
}

form {
  height: 100%;
  margin-left: 6rem;
}

.block {
  display: flex;
  align-items: center;
  margin-bottom: 8rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    @include type.bodyOne;
    margin-right: 5rem;
  }

  &.block--vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &.block--last {
    width: min-content;
  }

  .prompt {
    display: flex;
    align-items: center;
  }

  .guiding-text {
    @include type.bodyThree;
    color: colors.$grayTwo;
    margin-right: 2.5rem;
  }

  .open-feedback {
    background: none;
    border: 2px solid colors.$softPurple;
    border-radius: 4rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 3rem;
    color: colors.$grayOne;
    @include type.bodyThree;

    &:focus {
      background: fades.$subtleHighlight;
    }
  }

  .submit-button {
    background: colors.$graySix;
    color: colors.$grayFour;
    @include type.bodyOne;
    line-height: 1em;
    cursor: not-allowed;

    border: none;
    border-radius: 4rem;
    padding: 3.5rem 5rem 4.1rem;

    &.button-enabled {
      cursor: pointer;
      background: colors.$auroraGradient;
      color: colors.$distantStar;
      transition: all 0.3s ease-out;

      &:hover {
        background: colors.$risingGradient;
        transform: translateX(3rem) scale(1.02);
      }
    }
  }
}

.submit-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .last-submission {
    &__intro {
      @include type.bodyThree;
      color: colors.$grayThree;
    }

    &__date {
      @include type.bodyTwo;
      color: colors.$grayTwo;
      margin-left: 2.5rem;
    }
  }
}
</style>
