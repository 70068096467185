<template>
  <nav>
    <ul class="full-path">
      <li v-for="(path, index) in paths[address]" :key="path">
        <BreadcrumbPath
          :path="path"
          :isCurrent="index === paths[address].length - 1 ? true : false"
        />
      </li>
    </ul>
    <FleetingSignal />
    <div class="session">
      <span class="user-welcome">Welcome, {{ userName }}</span>
      <div :class="sessionControlsClasses">
        <!-- NOTE: THERE'S AN INVISIBLE CLICK LISTENER ATTACHED TO THE OPTIONSTOGGLER REF ELEMENT BELOW -->
        <img
          src="../../assets/icons/session-gear.svg"
          alt="settings icon"
          class="session__icon"
          ref="optionsToggler"
        />
        <ul class="session__controls__options" v-outclosable="dialogConfig">
          <li class="option" @click="goToSettings">Settings</li>
          <li class="option" @click="logOut">Log Out</li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import BreadcrumbPath from "./partials/BreadcrumbPath.vue";
import FleetingSignal from "../elements/FleetingSignal.vue";

export default {
  components: {
    BreadcrumbPath,
    FleetingSignal,
  },

  props: {
    address: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      paths: {
        commandsList: ["Home", "Commands"],
        newCommand: ["Home", "Commands", "New Command"],
        editCommand: ["Home", "Commands", "Edit Command"],
        stylingPage: ["Home", "Styling"],
        feedbackPage: ["Home", "Feedback"],
        settingsPage: ["Home", "Settings"],
      },

      sessionOptionsAreVisible: false,

      dialogConfig: {
        excludedElements: ["optionsToggler"], // must be an array of ref names
        togglingController: "optionsToggler", // must be a ref name
        closureHandler: "closeSessionOptions", // must be the name of a local method
        togglingHandler: "toggleSessionOptions", // must be the name of a local method
        dialogInfo: {
          stateProperty: "sessionOptionsAreVisible", // must be the name of a data property holding the dialog state
          revert: true, // set this to true if state is false when dialog is to be opened
        },
      },
    };
  },

  methods: {
    toggleSessionOptions() {
      this.sessionOptionsAreVisible = !this.sessionOptionsAreVisible;
    },
    closeSessionOptions() {
      this.sessionOptionsAreVisible = false;
    },
    goToSettings() {
      this.toggleSessionOptions();
      this.$store.dispatch("navigateToView", {
        view: "settingsPage",
        component: "SettingsPage",
      });
    },
    logOut() {
      this.$store.dispatch("logOut");
    },
  },

  computed: {
    userName() {
      return this.$store.getters.userName;
    },
    sessionControlsClasses() {
      return {
        session__controls: true,
        active: this.sessionOptionsAreVisible,
        inactive: !this.sessionOptionsAreVisible,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8rem;
  position: relative;
}

.full-path {
  background: fades.$subtleHighlight;
  display: flex;
  width: max-content;
  padding: 1rem 2rem;
  border-radius: 0.5em;
}

.session {
  display: flex;
  align-items: center;
  width: max-content;

  &__controls {
    display: flex;
    align-items: center;
    position: relative;

    &.active &__options {
      right: 50%;
      opacity: 1;
      display: block;
      pointer-events: all;
      cursor: pointer;
    }

    &__options {
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 130%;
      background: colors.$auroraGradient;
      color: colors.$distantStar;
      border-radius: 3rem 0 3rem 3rem;
      text-align: center;
      z-index: 10;
      overflow: hidden;
      width: 40rem;
      display: hidden;
      opacity: 0;
      transition: all 0.25s ease-out;

      .option {
        padding: 2rem 6rem;

        &:hover {
          background: fades.$gummyPurple;
          @include type.bodyTwo;
          font-weight: 700;
        }
      }
    }
  }

  &__icon {
    width: 10.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in;

    &:hover {
      transform: rotate(-20deg);
    }
  }
}

.user-welcome {
  @include type.special;
  margin-right: 6rem;
}
</style>
