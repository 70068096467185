<template>
  <div id="auth">
    <div id="welcome">
      <h1 class="headline">Welcome To The Private BETA</h1>
      <p class="intro">
        Shield yourself from distractions on YouTube.
        <br />
        Watch videos only when the time is right.
      </p>
      <a class="cta" href="https://timeboss.app" target="_blank">Learn More</a>
    </div>
    <div id="access">
      <form @submit.prevent>
        <div class="mode">
          <h1 :class="{ active: mode === 'Sign Up' }" @click="mode = 'Sign Up'">
            Sign Up
          </h1>
          <h1 class="active divider">/</h1>
          <h1 :class="{ active: mode === 'Log In' }" @click="mode = 'Log In'">
            Log In
          </h1>
        </div>
        <input
          type="text"
          placeholder="First Name"
          :class="{ invalid: nameIsInvalid }"
          v-model.trim="name"
          @blur="validateName"
          v-if="mode === 'Sign Up'"
        />
        <p class="alert" v-if="nameIsInvalid && mode === 'Sign Up'">
          {{ nameErrorMessage }}
        </p>
        <input
          type="text"
          placeholder="Email"
          :class="{ invalid: emailIsInvalid }"
          v-model.trim="email"
          @blur="validateEmail"
        />
        <p class="alert" v-if="emailIsInvalid">{{ emailErrorMessage }}</p>
        <input
          type="password"
          placeholder="Password"
          :class="{ invalid: passwordStatus === 'invalid' }"
          v-model="password"
          @keyup="validateNewPassword"
          @blur="validatePassword"
        />
        <p :class="['alert', passwordClasses]" v-if="passwordStatus">
          {{ passwordMessage }}
        </p>
        <button @click="validateAndSubmit">{{ mode }}</button>
        <p class="alert" v-if="formErrorMessage">{{ formErrorMessage }}</p>
        <p class="terms" v-if="mode === 'Sign Up'">
          By creating an account, you confirm that you've read and agree to our
          <a
            href="https://www.notion.so/Terms-of-Service-19d675210db74754a69044c70b0dc009"
            target="_blank"
            ><em>Terms of Service</em></a
          >
          and
          <a
            href="https://www.notion.so/Privacy-Policy-ebfbf408a3a24867bf58e7856044ea66"
            target="_blank"
            ><em>Privacy Policy.</em></a
          >
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mode: "Sign Up",
      name: "",
      email: "",
      password: "",
      nameIsInvalid: false,
      emailIsInvalid: false,
      passwordStatus: null,
      nameErrorMessage: null,
      emailErrorMessage: null,
      passwordMessage: null,
    };
  },

  computed: {
    passwordClasses() {
      return {
        validating: this.passwordStatus === "validating",
        valid: this.passwordStatus === "valid",
      };
    },
    formErrorMessage() {
      return this.$store.getters.authError || "";
    },
  },

  watch: {
    mode() {
      this.passwordStatus = null;
    },
  },

  methods: {
    validateName() {
      this.nameIsInvalid = false;

      if (this.name === "") {
        this.nameIsInvalid = true;
        this.nameErrorMessage = "Please enter a name.";
      }
    },
    validateEmail() {
      this.emailIsInvalid = false;

      if (this.email === "") {
        this.emailIsInvalid = true;
        this.emailErrorMessage = "Please enter an email.";
      } else if (!this.email.includes("@")) {
        this.emailIsInvalid = true;
        this.emailErrorMessage = "This email is missing the '@' symbol.";
      }
    },
    validateNewPassword() {
      if (this.mode === "Log In") return;
      if (this.password.length < 8) {
        this.passwordStatus = "validating";
        this.passwordMessage =
          "Almost there. Your password should have at least 8 characters.";
      } else {
        this.passwordStatus = "valid";
        this.passwordMessage = "Looks great!";
      }
    },
    validatePassword() {
      this.passwordStatus = null;

      if (this.password === "") {
        this.passwordStatus = "invalid";
        this.passwordMessage = "Please enter a password.";
      } else if (this.password.length < 8) {
        this.passwordStatus = "invalid";
        this.passwordMessage = "Incorrect password. Please try again.";
      }
    },
    validateAndSubmit() {
      this.$store.commit("clearAuthErrors");

      this.validateEmail();
      this.validatePassword();

      const validationOutcomes = [this.emailIsInvalid, this.passwordStatus];

      if (this.mode === "Sign Up") {
        this.validateName();
        validationOutcomes.push(this.nameIsInvalid);
      }

      if (
        validationOutcomes.includes(true) ||
        validationOutcomes.includes("invalid")
      ) {
        return;
      }

      try {
        this.$store.dispatch("authenticateUser", {
          name: this.name,
          email: this.email,
          password: this.password,
          mode: this.mode === "Sign Up" ? "signUp" : "logIn",
        });
      } catch (error) {
        console.log("caught error");
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#auth {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  button,
  a {
    line-height: 1em;
    cursor: pointer;
    transition: all 0.25s ease-out;
  }

  #welcome {
    height: 100%;
    width: 100%;
    background: colors.$auroraGradient;
    display: grid;
    grid-template-rows: repeat(3, max-content);
    row-gap: 6rem;
    justify-items: center;
    align-content: center;

    .intro {
      text-align: center;
    }

    .cta {
      background: transparent;
      padding: 2.5rem 5rem 3rem;
      border: 1px solid colors.$brightTurquoise;
      border-radius: 6rem;
      @include type.bodyOne;

      &:hover {
        background: colors.$auroraGradient;
        transform: translateX(3px);
        box-shadow: shadows.$spell;
      }
    }
  }

  #access {
    background: colors.$spaceGradient;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      width: 80%;
      border: 2px solid colors.$softPurple;
      border-radius: 8rem;
      display: grid;
      justify-items: center;
      row-gap: 6rem;
      padding: 10%;

      .alert {
        @include type.bodyThree;
        justify-self: start;
        margin-top: -3rem;
        color: colors.$watermelonSignal;

        &.validating {
          color: colors.$peachSignal;
        }

        &.valid {
          color: colors.$limeSignal;
        }
      }
    }

    .mode {
      display: flex;
      justify-content: center;
      color: colors.$grayThree;
      text-transform: uppercase;

      .active {
        color: colors.$distantStar;
      }

      .divider {
        margin: 0 6rem;
      }

      h1:not(.divider) {
        cursor: pointer;
      }
    }

    input,
    button {
      display: block;
      @include type.bodyOne;
      color: colors.$distantStar;
    }

    input {
      background: transparent;
      border: 1px solid colors.$softPurple;
      border-radius: 4rem;
      padding: 2.5rem 6rem 3rem;
      width: 100%;
      outline: none;

      &.invalid {
        border: 1px solid colors.$watermelonSignal;
      }

      &:focus {
        border: 2px solid colors.$softPurple;
        background: fades.$subtleHighlight;
      }
    }

    button {
      background: colors.$risingGradient;
      border: none;
      border-radius: 8rem;
      padding: 3rem 8rem 3.5rem;
      text-transform: uppercase;
      box-shadow: shadows.$spell;

      &:hover {
        transform: translateX(3px);
      }
    }

    .terms {
      margin-top: 4rem;
      margin-bottom: -4rem;

      @include type.captionTwo;
      color: colors.$grayThree;

      a {
        @include type.captionOne;
        color: colors.$grayTwo;
      }
    }
  }
}
</style>
