<template>
  <main>
    <h2>My Commands</h2>
    <section class="scroll-container">
      <p v-if="isFetchingCommands" class="loading-msg">
        Loading your commands...
      </p>
      <ul v-if="commands.length > 0">
        <CommandItem
          v-for="(command, index) in commands"
          :key="command.id"
          :commandId="command.id"
          :platform="command.platform"
          :index="index"
          :name="command.name"
          :type="command.type"
          :sourcesCount="command.listedSources.length"
          :interval="command.interval"
          :dailyLimit="command.dailyLimit"
          :activeDays="command.activeDays"
          :isEnabled="command.isEnabled"
          @toggleCommand="onToggleCommand"
        />
      </ul>
      <div class="empty-state" v-else>
        <img
          src="../../assets/icons/commands-empty-state.svg"
          alt="UFO illustration for empty state"
          class="empty-state__illustration"
        />
        <p class="empty-state__message">You don't have any commands yet.</p>
        <primary-button
          icon="plusIcon"
          class="empty-state__cta"
          @click="goToNewCommand"
        >
          Create My First Command Now
        </primary-button>
      </div>
    </section>
  </main>
</template>

<script>
import CommandItem from "../elements/CommandItem.vue";
import PrimaryButton from "../elements/PrimaryButton.vue";

export default {
  components: {
    CommandItem,
    PrimaryButton,
  },

  computed: {
    commands() {
      return this.$store.getters["commands/commands"];
    },
    isFetchingCommands() {
      return this.$store.getters["commands/isFetchingCommands"];
    },
  },

  methods: {
    onToggleCommand(commandIdentifiers) {
      this.$store.dispatch("commands/toggleCommand", commandIdentifiers);
    },
    goToNewCommand() {
      this.$store.dispatch("navigateToView", {
        view: "newCommand",
        component: "CommandSetup",
      });
    },
  },

  mounted() {
    this.$store.dispatch("commands/fetchCommands");
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

h2 {
  margin-bottom: 5rem;
}

section {
  overflow: auto;
}

ul {
  height: 100%;
  margin-left: 6rem;
  // margin-right: 6rem;
}

.empty-state {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__illustration {
    width: 15%;
    margin-top: -5%;
  }

  &__message {
    margin-top: -2rem;
  }

  &__cta {
    margin-top: 8rem;
  }
}

.loading-msg {
  @include type.captionTwo;
  color: colors.$softPurple;
  margin-top: 5rem;
  margin-bottom: 8rem;
  text-align: center;
}
</style>
