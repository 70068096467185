<template>
  <span :class="activePath">{{ path }}</span
  ><span v-if="!isCurrent">/</span>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    activePath() {
      return { "breadcrumb-active": this.isCurrent };
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  @include type.captionTwo;
  color: colors.$grayFour;
  padding: 0 0.8rem;

  &.breadcrumb-active {
    color: colors.$grayTwo;
  }
}
</style>
