import { createStore } from "vuex";
import commandsModule from "./modules/commands";
import stylingModule from "./modules/styling";
import feedbackModule from "./modules/feedback";
import settingsModule from "./modules/settings";

let signalTimer = null;
let refreshTokenTimer = null;
let autoLogOutTimer = null;

export default createStore({
  modules: {
    commands: commandsModule,
    styling: stylingModule,
    feedback: feedbackModule,
    settings: settingsModule,
  },
  state: {
    databaseURL: "https://time-boss-app-default-rtdb.firebaseio.com",
    token: null,
    userId: null,
    userName: null,
    email: null,
    error: null,
    authError: null,
    currentView: "commandsList",
    currentSectionComponent: "CommandsList",
    aSignalIsVisible: false,
    pageSignal: {
      icon: "tickMark",
      message: "Sent! Thank you for your feedback :)",
      type: "positive",
    },
  },
  getters: {
    databaseURL(state) {
      return state.databaseURL;
    },
    isLoggedIn(state) {
      return state.userId !== null;
    },
    token(state) {
      return state.token;
    },
    userId(state) {
      return state.userId;
    },
    userName(state) {
      return state.userName;
    },
    email(state) {
      return state.email;
    },
    databaseEssentials(_, getters) {
      return {
        databaseURL: getters.databaseURL,
        token: getters.token,
        userId: getters.userId,
      };
    },
    authError(state) {
      return state.authError;
    },
    currentView(state) {
      return state.currentView;
    },
    currentSectionComponent(state) {
      return state.currentSectionComponent;
    },
    aSignalIsVisible(state) {
      return state.aSignalIsVisible;
    },
    pageSignal(state) {
      return state.pageSignal;
    },
  },
  mutations: {
    setUser(state, userInfo) {
      for (const key in userInfo) {
        state[key] = userInfo[key];
      }
    },
    setView(state, { view, component }) {
      state.currentView = view;
      state.currentSectionComponent = component;
    },
    setAuthError(state, { message }) {
      state.authError = message;
    },
    setError({ error }) {
      this.error = error;
    },
    clearAuthErrors(state) {
      state.authError = null;
    },
    setPageSignal(state, signal) {
      state.aSignalIsVisible = true;
      state.pageSignal = signal;
    },
    clearPageSignal(state) {
      state.aSignalIsVisible = false;
    },
  },
  actions: {
    async refreshAuthToken(context) {
      const savedRefreshToken = encodeURIComponent(
        localStorage.getItem("refreshToken")
      );

      const response = await fetch(
        "https://securetoken.googleapis.com/v1/token?key=AIzaSyCcMeRo7ssnPed81AUX7Ato_IsJ_z9LRL0",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `grant_type=refresh_token&refresh_token=${savedRefreshToken}`,
        }
      );

      if (!response.ok) {
        console.log("Failed to refresh auth token.");
        console.log(response);
        context.dispatch("logOut");
        return;
      }

      const responseData = await response.json();

      const userId = responseData.user_id;
      const newToken = responseData.id_token;
      const newRefreshToken = responseData.refresh_token;
      const tokenExpiresInMs = responseData.expires_in * 1000; // expires in was in seconds, so we multiply by 1000 to convert to ms
      const tokenExpirationDate = Date.now() + tokenExpiresInMs;

      // Update user and token info in local storage
      localStorage.setItem("userId", userId);
      localStorage.setItem("token", newToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      localStorage.setItem("tokenExpirationDate", tokenExpirationDate);

      // Also update user and token in Vuex store.
      context.commit("setUser", { userId, token: newToken });

      // Schedule next refresh to 5 min before the new token expires
      clearTimeout(refreshTokenTimer);

      refreshTokenTimer = setTimeout(() => {
        context.dispatch("refreshAuthToken");
      }, Math.max(tokenExpiresInMs - 300000, 0));

      clearTimeout(autoLogOutTimer);

      autoLogOutTimer = setTimeout(() => {
        context.dispatch("autoLogOutIfTokenExpired"); // this will *only* log us out if the token has expired
      }, tokenExpiresInMs);
    },

    async tryAutoLogIn(context) {
      let tokenExpirationDate = localStorage.getItem("tokenExpirationDate");

      if (!tokenExpirationDate) return;

      const tokenExpiresInMs = tokenExpirationDate - Date.now();

      if (Date.now() > tokenExpirationDate) {
        await context.dispatch("refreshAuthToken");
        tokenExpirationDate = localStorage.getItem("tokenExpirationDate"); // get the lates expiration date again
      } else {
        // Schedule next refresh to 5 min before the new token expires
        clearTimeout(refreshTokenTimer);

        refreshTokenTimer = setTimeout(() => {
          context.dispatch("refreshAuthToken");
        }, Math.max(tokenExpiresInMs - 300000, 0));

        clearTimeout(autoLogOutTimer);

        autoLogOutTimer = setTimeout(() => {
          context.dispatch("autoLogOutIfTokenExpired"); // this will *only* log us out if the token has expired
        }, tokenExpiresInMs);
      }

      if (Date.now() > tokenExpirationDate) {
        console.log("Refresh token failed");
        return;
      }

      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (token && userId) {
        context.commit("setUser", { token, userId });
        context.dispatch("fetchBasicInfo");
        context.dispatch("updateLastAppVisit");
      }
    },

    autoLogOutIfTokenExpired(context) {
      const tokenExpirationDate = localStorage.getItem("tokenExpirationDate");

      if (Date.now() > tokenExpirationDate) {
        // this means the token expired (so long as the refreshToken action and related timer is doing its job, we shouldn't get here)
        // I'm just adding this function so that, if for whatever reason the user is logged out, the UI reflects that instead of just being
        // dead by not reacting to the requests a user makes
        context.dispatch("logOut");
      }
    },

    async authenticateUser(context, { name = null, email, password, mode }) {
      context.commit("clearAuthErrors");

      const targetUrl =
        mode === "signUp"
          ? "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCcMeRo7ssnPed81AUX7Ato_IsJ_z9LRL0"
          : "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCcMeRo7ssnPed81AUX7Ato_IsJ_z9LRL0";

      const response = await fetch(targetUrl, {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          returnSecureToken: true,
        }),
      });

      if (!response.ok) {
        this.commit("setAuthError", {
          message: "Invalid credentials. Please try again.",
        });
        return;
      }

      const responseData = await response.json();
      const userId = responseData.localId;
      const token = responseData.idToken;
      const refreshToken = responseData.refreshToken;
      const tokenExpiresInMs = responseData.expiresIn * 1000; // expires in was in seconds, so we multiply by 1000 to convert to ms
      const tokenExpirationDate = Date.now() + tokenExpiresInMs;

      localStorage.setItem("userId", userId);
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("tokenExpirationDate", tokenExpirationDate);

      context.commit("setUser", { token, userId });

      clearTimeout(refreshTokenTimer);

      refreshTokenTimer = setTimeout(() => {
        context.dispatch("refreshAuthToken"); // the auth tokens we get usually expire in one hour, so we need to refresh them
      }, Math.max(tokenExpiresInMs - 300000, 0));
      // ...to play it safe, I'm scheduling the refresh request to five minutes before the token we have expires

      clearTimeout(autoLogOutTimer);
      autoLogOutTimer = setTimeout(() => {
        context.dispatch("autoLogOutIfTokenExpired"); // this will *only* log us out if the token has expired
      }, tokenExpiresInMs);

      if (mode === "logIn") {
        context.dispatch("fetchBasicInfo");
      } else if (mode === "signUp") {
        context.commit("setUser", { userName: name, email: email });
        context.dispatch("initializeNewUser");
      }

      context.dispatch("updateLastAppVisit");
    },

    async initializeNewUser(context) {
      const { databaseURL, token, userId } = context.getters.databaseEssentials;
      const userName = context.getters.userName;
      const email = context.getters.email;

      const basicInfoInitialization = await fetch(
        `${databaseURL}/basicInfo/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            name: userName,
            email: email,
          }),
        }
      );

      if (!basicInfoInitialization.ok) {
        console.log(basicInfoInitialization);
        return;
      }

      const accountInfoInitialization = await fetch(
        `${databaseURL}/accountInfo/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            joined: Date.now(),
          }),
        }
      );

      if (!accountInfoInitialization.ok) {
        console.log(accountInfoInitialization);
        return;
      }

      const hideableItems = context.getters["styling/hideableItems"];
      const initialHiddenStatuses = {};

      for (const item of hideableItems) {
        initialHiddenStatuses[item.id] = false;
      }

      const hiddenItemsInitialization = await fetch(
        `${databaseURL}/hideableItems/${userId}.json?auth=${token}`,
        {
          method: "PUT",
          body: JSON.stringify({
            YouTube: initialHiddenStatuses,
          }),
        }
      );

      if (!hiddenItemsInitialization.ok) {
        console.log(hiddenItemsInitialization);
        return;
      }

      const userSettingsInitialization = await fetch(
        `${databaseURL}/userSettings/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            globalSettings: {
              daysStartAt: {
                hours: 0,
                minutes: 0,
              },
              bossHaven: "",
            },
          }),
        }
      );

      if (!userSettingsInitialization.ok) {
        console.log(userSettingsInitialization);
        return;
      }
    },

    async fetchBasicInfo(context) {
      const { databaseURL, token, userId } = context.getters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/basicInfo/${userId}.json?auth=${token}`
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      const basicInfo = await response.json();

      context.commit("setUser", {
        userName: basicInfo.name,
        email: basicInfo.email,
      });
    },

    async updateLastAppVisit(context) {
      const { databaseURL, token, userId } = context.getters.databaseEssentials;

      const lastVisitUpdate = await fetch(
        `${databaseURL}/accountInfo/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            lastAppVisit: new Date().toGMTString(),
          }),
        }
      );

      if (!lastVisitUpdate.ok) {
        console.log(lastVisitUpdate);
        return;
      }
    },

    logOut(context) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");

      clearTimeout(refreshTokenTimer); // so we are not auto logged in when a token is refreshed
      clearTimeout(autoLogOutTimer);

      context.commit("setUser", {
        token: null,
        userId: null,
        userName: null,
        email: null,
      });
    },

    createFetchError(context, { response, altMessage }) {
      const error = new Error(response.message || altMessage);
      context.commit("setError", { error });
      throw error;
    },

    navigateToView(context, payload) {
      // the payload must have properties "view" and "component"
      if (payload.view !== "editCommand") {
        context.dispatch("commands/clearCommandToEdit");
      }

      context.commit("setView", payload);
    },

    setPageSignal(context, signal) {
      // signal payload must be an object with properties
      // icon, message, and type

      context.commit("setPageSignal", signal);

      const delay = 1000 + signal.message.length * 40;

      clearTimeout(signalTimer);

      signalTimer = setTimeout(() => {
        context.commit("clearPageSignal");
      }, delay);
    },
  },
});
