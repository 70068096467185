<template>
  <button :class="buttonClasses" type="button">
    <PrimaryFormButtonIcon
      v-if="!executesMainAction"
      :icon="icon"
      :class="iconClass"
      :iconClass="iconClass"
    />
    <span>{{ label }}</span>
    <PrimaryFormButtonIcon
      v-if="executesMainAction"
      :icon="icon"
      :class="iconClass"
      :iconClass="iconClass"
    />
  </button>
</template>

<script>
import PrimaryFormButtonIcon from "./PrimaryFormButtonIcon.vue";

export default {
  components: {
    PrimaryFormButtonIcon,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    executesMainAction: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    buttonClasses() {
      return {
        "action-disabled": this.isDisabled,
        "main-action": this.executesMainAction,
        "secondary-action": !this.executesMainAction,
      };
    },
    iconClass() {
      if (this.isDisabled) return "action-disabled";
      if (this.executesMainAction) return "main-action";
      return "secondary-action";
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  background: colors.$graySix;
  padding: 3rem 6rem;
  display: flex;
  align-items: center;
  @include type.bodyOne;
  color: colors.$grayThree;
  cursor: pointer;

  &.main-action {
    background: colors.$auroraGradient;

    & > span {
      margin-right: 3rem;
      color: colors.$distantStar;
    }
  }

  &.secondary-action > span {
    margin-left: 3rem;
  }

  &.action-disabled {
    background: colors.$graySix;
    cursor: not-allowed;

    & > span {
      color: colors.$grayFour;
    }
  }
}
</style>
