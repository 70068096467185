<template>
  <img
    :src="require(`../../assets/icons/${checkboxType}.svg`)"
    alt="checkbox"
    class="checkbox"
    @click="toggleSelection"
  />
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  computed: {
    checkboxType() {
      if (this.isSelected) {
        return "checkbox-selected";
      }
      return "checkbox-unselected";
    },
  },

  methods: {
    toggleSelection() {
      this.$emit("checkboxUpdate", !this.isSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;
}
</style>
