<template>
  <div id="interface" v-if="isLoggedIn" :class="{ 'in-view': isLoggedIn }">
    <!-- <nav class="menu"></nav> -->
    <TheSidebar id="sidebar" />
    <div id="canvas">
      <TheTop id="top" :address="currentView" />
      <TheHeader id="header" />
      <keep-alive>
        <component
          :is="mainActiveComponent"
          v-show="!ephemeralActiveComponent"
        ></component>
      </keep-alive>
      <CommandSetup v-if="ephemeralActiveComponent === 'CommandSetup'" />
      <TheFooter />
    </div>
  </div>
  <UserAuth v-else id="auth" />
  <div
    id="extension-connector"
    :data-uid="userId"
    ref="extensionConnector"
  ></div>
</template>

<script>
import TheSidebar from "./components/layout/TheSidebar.vue";
import TheTop from "./components/layout/TheTop.vue";
import TheHeader from "./components/layout/TheHeader.vue";
import CommandsList from "./components/sections/CommandsList.vue";
import CommandSetup from "./components/sections/CommandSetup.vue";
import HideableItems from "./components/sections/HideableItems.vue";
import UserFeedback from "./components/sections/UserFeedback.vue";
import SettingsPage from "./components/sections/SettingsPage.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import UserAuth from "./components/sections/UserAuth.vue";

export default {
  components: {
    TheSidebar,
    TheTop,
    TheHeader,
    CommandsList,
    CommandSetup,
    HideableItems,
    UserFeedback,
    SettingsPage,
    TheFooter,
    UserAuth,
  },

  watch: {
    userId(newValue) {
      if (!newValue || !this.$refs.extensionConnector) return;

      this.$refs.extensionConnector.dispatchEvent(
        new CustomEvent("userIdUpdate", {
          detail: { userId: newValue },
        })
      );
    },
    lastCommandsChange() {
      this.$refs.extensionConnector.dispatchEvent(
        new CustomEvent("choicesUpdate", {
          detail: { module: "commands" },
        })
      );
    },
    lastStylingChange() {
      this.$refs.extensionConnector.dispatchEvent(
        new CustomEvent("choicesUpdate", {
          detail: { module: "styling" },
        })
      );
    },
    lastSettingsChange() {
      this.$refs.extensionConnector.dispatchEvent(
        new CustomEvent("choicesUpdate", {
          detail: { module: "settings" },
        })
      );
    },
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    mainActiveComponent() {
      const activeComponent = this.$store.getters.currentSectionComponent;
      if (activeComponent === "CommandSetup") return;
      return activeComponent;
    },
    ephemeralActiveComponent() {
      const activeComponent = this.$store.getters.currentSectionComponent;
      if (activeComponent !== "CommandSetup") return null;
      return activeComponent;
    },
    currentView() {
      return this.$store.getters.currentView;
    },
    userId() {
      return this.$store.getters.userId;
    },
    lastCommandsChange() {
      return this.$store.getters["commands/lastCommandsChange"];
    },
    lastStylingChange() {
      return this.$store.getters["styling/lastStylingChange"];
    },
    lastSettingsChange() {
      return this.$store.getters["settings/lastSettingsChange"];
    },
  },

  created() {
    this.$store.dispatch("tryAutoLogIn");
  },
};
</script>

<style lang="scss">
@use "@/scss/layout";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

#extension-connector {
  display: hidden;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: clamp(2.5px, 0.2083vw, 6px);
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  background: colors.$spaceGradient;

  font-family: "Nunito Sans", sans-serif;
  color: colors.$distantStar;
  @include type.bodyThree;
}

h1 {
  @include type.headerOne;
}

h2 {
  @include type.headerTwo;
}

p {
  @include type.bodyTwo;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: colors.$distantStar;
}

button {
  color: colors.$distantStar;
  outline: none;
}

input {
  outline: none;
}

textarea {
  font-family: "Nunito Sans", sans-serif;
  resize: none;
  outline: none;
}

// Scrollbar Styles:
::-webkit-scrollbar {
  width: 0rem;
}

* {
  scrollbar-width: none;
}

// Default Placeholder styles:
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: colors.$grayThree;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: colors.$grayThree;
}
</style>
