<template>
  <main>
    <h2>YouTube Elements</h2>
    <section class="overflow-container">
      <p v-if="isFetchingItemStatuses" class="loading-msg">
        Updating your styling preferences...
      </p>
      <ul>
        <HideableItem
          v-for="(item, index) in hideableItems"
          :key="item.id"
          :itemName="item.name"
          :description="item.description"
          :itemIsHidden="item.isHidden"
          :index="index"
        />
      </ul>
    </section>
  </main>
</template>

<script>
import HideableItem from "../elements/HideableItem.vue";

export default {
  components: {
    HideableItem,
  },

  computed: {
    hideableItems() {
      return this.$store.getters["styling/hideableItems"];
    },
    isFetchingItemStatuses() {
      return this.$store.getters["styling/isFetchingItemStatuses"];
    },
  },

  mounted() {
    this.$store.dispatch("styling/fetchHideableItems");
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}

h2 {
  margin-bottom: 5rem;
}

section {
  overflow: auto;
  padding-right: 5rem;

  /*width*/
  &::-webkit-scrollbar {
    width: clamp(3px, 2.25rem, 8px);
  }
  /*track*/
  &::-webkit-scrollbar-track {
    background: colors.$grayFive;
    border-style: none;
    border-radius: 10rem;
  }
  /*thumb*/
  &::-webkit-scrollbar-thumb {
    background: colors.$auroraGradient;
    border-radius: 10rem;
  }
  /*thumb pressed*/
  &::-webkit-scrollbar-thumb:active {
    background: colors.$aquaGradient;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: colors.$grayThree colors.$grayFive;
  }
}

.loading-msg {
  @include type.captionTwo;
  color: colors.$softPurple;
  margin-top: 5rem;
  margin-bottom: 8rem;
  text-align: center;
}
</style>
