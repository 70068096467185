<template>
  <li class="pill">
    <span class="label">{{ label }}</span>

    <svg
      :width="size"
      :height="size"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="cross"
      @click="$emit('closePill')"
    >
      <path
        d="M21.5 7.50003C21.2812 7.28132 20.9846 7.15845 20.6752 7.15845C20.3658 7.15845 20.0691 7.28132 19.8504 7.50003L14.5 12.8504L9.1497 7.50003C8.93092 7.28132 8.63422 7.15845 8.32486 7.15845C8.01551 7.15845 7.71881 7.28132 7.50003 7.50003C7.28132 7.71881 7.15845 8.01551 7.15845 8.32486C7.15845 8.63422 7.28132 8.93092 7.50003 9.1497L12.8504 14.5L7.50003 19.8504C7.28132 20.0691 7.15845 20.3658 7.15845 20.6752C7.15845 20.9846 7.28132 21.2812 7.50003 21.5C7.71881 21.7187 8.01551 21.8416 8.32486 21.8416C8.63422 21.8416 8.93092 21.7187 9.1497 21.5L14.5 16.1497L19.8504 21.5C20.0691 21.7187 20.3658 21.8416 20.6752 21.8416C20.9846 21.8416 21.2812 21.7187 21.5 21.5C21.7187 21.2812 21.8416 20.9846 21.8416 20.6752C21.8416 20.3658 21.7187 20.0691 21.5 19.8504L16.1497 14.5L21.5 9.1497C21.7187 8.93092 21.8416 8.63422 21.8416 8.32486C21.8416 8.01551 21.7187 7.71881 21.5 7.50003Z"
      />
    </svg>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "5.5rem",
    },
  },
};
</script>

<style lang="scss" scoped>
.pill {
  display: flex;
  align-items: center;
  border: 1px solid colors.$brightTurquoise;
  border-radius: 2em;
  padding: 0.3rem 3.3rem;
  margin-right: 2rem;
  margin-top: 2rem;

  .label {
    color: colors.$brightTurquoise;
    @include type.bodyThree;
  }

  .cross {
    fill: colors.$brightTurquoise;
    margin-bottom: 0.85rem;
    margin-left: 1rem;
    cursor: pointer;
  }
}
</style>
