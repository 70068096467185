<template>
  <button>
    <PrimaryButtonIcon :icon="icon" />
    <span><slot></slot></span>
  </button>
</template>

<script>
import PrimaryButtonIcon from "./PrimaryButtonIcon.vue";

export default {
  components: {
    PrimaryButtonIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;

  @include type.bodyTwo;
  background: colors.$auroraGradient;
  padding: 3rem 7rem;
  border-radius: 9rem;
  box-shadow: shadows.$spell;
  cursor: pointer;

  span {
    padding-bottom: 0.5rem;
  }

  // For border with gradient:
  background-clip: padding-box;
  border: 1px solid transparent;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: fades.$glowingStroke;
  }

  &:hover {
    transform: translateY(-0.8rem) scale(1.04);

    &::before {
      display: none;
    }
  }
}
</style>
