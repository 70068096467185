export const configForOutclosableDirective = {
  mounted(boundElement, binding) {
    // generate closure handling function
    const handleClicksOutsideDialog = (event) => {
      event.stopPropagation();

      const { excludedElements, closureHandler } = binding.value;

      let anExcludedElementWasClicked = false;

      excludedElements.forEach((refName) => {
        if (anExcludedElementWasClicked) return;
        // ...only runs if we haven't detected an excluded element yet

        // Get the element using the reference name
        const excludedElement = binding.instance.$refs[refName];

        anExcludedElementWasClicked = excludedElement?.contains(event.target);
        //...using an optional chaining operator (?) because after logging out
        // excludedElement will suddenly become null
      });

      // did we click on the dialog element or an excluded element?
      if (boundElement.contains(event.target) || anExcludedElementWasClicked) {
        return; // these are not considered "outside clicks"
      }

      // if we're here, we clicked outside the dialog element, so close it
      document.removeEventListener("click", handleClicksOutsideDialog);
      binding.instance[closureHandler]();
    };

    // generate an v-on:click equivalent that toggles the dialog and checks for outside clicks
    const handleDirectDialogToggling = (event) => {
      event.stopPropagation();
      // remove any outside click listener that may still be around
      document.removeEventListener("click", handleClicksOutsideDialog);

      const { togglingHandler, dialogInfo } = binding.value;

      const dialogState = binding.instance[dialogInfo.stateProperty];
      const dialogIsToBeOpened = dialogInfo.revert ? !dialogState : dialogState;

      if (dialogIsToBeOpened) {
        document.addEventListener("click", handleClicksOutsideDialog);
      }

      binding.instance[togglingHandler](); // toggle dialog
    };

    const togglingControllerRef = binding.value.togglingController;
    const togglingController = binding.instance.$refs[togglingControllerRef];

    // remove any toggling handlers in case the element was unmounted and the listener was already added
    togglingController.removeEventListener("click", handleDirectDialogToggling);
    togglingController.addEventListener("click", handleDirectDialogToggling); // now we're confident this is only added once
  },
};
