export default {
  namespaced: true,
  state() {
    return {
      latestSubmissionDate: null,
      isFetchingSubmissionDate: false,
      monthNames: {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December",
      },
    };
  },
  getters: {
    latestSubmissionDate(state) {
      return state.latestSubmissionDate;
    },
    isFetchingSubmissionDate(state) {
      return state.isFetchingSubmissionDate;
    },
    monthNames(state) {
      return state.isFetchingSubmissionDate;
    },
  },
  mutations: {
    setFetchingStatus(state, status) {
      state.isFetchingSubmissionDate = status;
    },
    setLatestSubmissionDate(state, timeStamp) {
      const [monthNumber, day, year] = new Date(timeStamp)
        .toLocaleDateString()
        .split("/");

      const monthName = state.monthNames[monthNumber];

      state.latestSubmissionDate = `${monthName} ${day}, ${year}`;
    },
  },
  actions: {
    async fetchLatestSubmissionDate(context) {
      context.commit("setFetchingStatus", true);

      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/feedbackSnapshots/${userId}/latestSubmissionDate.json?auth=${token}`
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      context.commit("setFetchingStatus", false);

      const latestSubmissionDate = await response.json();

      if (!latestSubmissionDate) return;

      context.commit("setLatestSubmissionDate", latestSubmissionDate);
    },

    async submitFeedback(context, feedback) {
      const {
        databaseURL,
        token,
        userId,
      } = context.rootGetters.databaseEssentials;

      const response = await fetch(
        `${databaseURL}/feedbackSnapshots/${userId}/submissions.json?auth=${token}`,
        {
          method: "POST",
          body: JSON.stringify(feedback),
        }
      );

      if (!response.ok) {
        console.log(response);
        return;
      }

      const updateLatestSubmissionDate = await fetch(
        `${databaseURL}/feedbackSnapshots/${userId}.json?auth=${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            latestSubmissionDate: Date.parse(feedback.dateSubmitted),
          }),
        }
      );

      if (!updateLatestSubmissionDate.ok) {
        console.log(response);
        return;
      }

      context.dispatch("fetchLatestSubmissionDate");

      context.dispatch(
        "setPageSignal",
        {
          icon: "tickMark",
          message: "Sent! Thank you for your feedback :)",
          type: "positive",
        },
        { root: true }
      );
    },
  },
};
