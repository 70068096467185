<template>
  <main>
    <form @submit.prevent>
      <input
        type="text"
        class="command-name"
        placeholder='Name your command here (eg: "Go to sleep on time!")'
        v-model.trim="name"
        maxlength="40"
      />
      <div class="command-controls">
        <!-- BLOCK VIDEOS FROM (SCOPE) -->
        <div class="block">
          <span class="block__label">Block videos from:</span>
          <LabeledToggle
            optionA="The Channels I List"
            optionB="Any Channels I Don't List"
            valueA="inclusive"
            valueB="exclusive"
            :aIsSelected="scope === 'inclusive'"
            @selectionUpdate="onScopeUpdate"
          />
        </div>

        <!-- LISTED CHANNELS -->
        <div class="block block--vertical">
          <div class="row">
            <span class="block__label">Listed channels:</span>
            <RegularTypeInput
              class="channel-list"
              placeholder="enter a channel name as it appears on YT (eg: Warner Records)"
              icon="plusSign"
              :clearOnSubmit="true"
              :widthMultiplier="0.85"
              iconAction="enter"
              @submitInput="addChannel"
            />
          </div>
          <p class="row error-text" v-if="sourceError">{{ sourceError }}</p>
          <p class="row informative-text" v-show="listedChannels.length === 0">
            {{ channelsNote }}
          </p>
          <ul class="pills row row--last" v-show="listedChannels.length > 0">
            <BasicPill
              v-for="(channel, index) in listedChannels"
              :key="channel"
              :label="channel"
              @closePill="onClosePill(index)"
            />
          </ul>
        </div>

        <!-- WHEN TO BLOCK? (TRIGGER) -->
        <div class="block">
          <span class="block__label">When to block?:</span>
          <LabeledToggle
            optionA="Immediately"
            optionB="After I exceed a Time Limit"
            valueA="direct"
            valueB="allowance"
            :aIsSelected="trigger === 'direct'"
            @selectionUpdate="onTriggerUpdate"
          />
        </div>

        <!-- ACTIVE INTERVAL -->
        <div
          class="block block--vertical block__interval"
          v-show="trigger === 'direct'"
          @click="prevalidate('intervalError')"
        >
          <div class="row row--last">
            <span class="block__label" :class="intervalIsInvalid"
              >Active interval:</span
            >
            <span class="guiding-text">All day?</span>
            <BasicCheckbox
              :isSelected="isActiveAllDay"
              @checkboxUpdate="onCheckboxUpdate"
              class="checkbox"
            />
            <div class="interval-definition" v-if="!isActiveAllDay">
              <span class="guiding-text from">from</span>
              <RegularTypeInput
                :placeholder="'  '"
                v-model.trim="startHours"
                type="number"
                :max="12"
                :min="0"
              />
              <span class="guiding-text colons">:</span>
              <RegularTypeInput
                :placeholder="'  '"
                v-model.trim="startMinutes"
                type="number"
                :max="59"
                :min="0"
              />
              <LabeledToggle
                optionA="am"
                optionB="pm"
                valueA="am"
                valueB="pm"
                :aIsSelected="startMeridiem === 'am'"
                @selectionUpdate="onStartMeridiemUpdate"
                class="meridiem-toggle"
              />
              <span class="guiding-text to">to</span>
              <RegularTypeInput
                :placeholder="'  '"
                v-model.trim="endHours"
                type="number"
                :max="12"
                :min="0"
              />
              <span class="guiding-text colons">:</span>
              <RegularTypeInput
                :placeholder="'  '"
                v-model.trim="endMinutes"
                type="number"
                :max="59"
                :min="0"
              />
              <LabeledToggle
                optionA="am"
                optionB="pm"
                valueA="am"
                valueB="pm"
                :aIsSelected="endMeridiem === 'am'"
                @selectionUpdate="onEndMeridiemUpdate"
                class="meridiem-toggle"
              />
            </div>
          </div>
          <p class="error-text" v-if="intervalError">{{ intervalError }}</p>
        </div>

        <!-- DAILY TIME LIMIT (ALLOWANCE) -->
        <div
          class="block block--vertical"
          v-show="trigger === 'allowance'"
          @click="prevalidate('allowanceError')"
        >
          <div class="row row--last allowance-definition">
            <span class="block__label" :class="allowanceIsInvalid"
              >Daily time limit:</span
            >
            <RegularTypeInput
              :placeholder="'  '"
              v-model.trim="allowanceHours"
              type="number"
              :max="23"
              :min="0"
            />
            <span class="guiding-text">hours</span>
            <RegularTypeInput
              :placeholder="'  '"
              v-model.trim="allowanceMinutes"
              type="number"
              :max="59"
              :min="0"
              class="inline-section"
            />
            <span class="guiding-text">minutes</span>
          </div>
          <p class="error-text" v-if="allowanceError">{{ allowanceError }}</p>
        </div>

        <!-- ACTIVE DAYS -->
        <div
          class="block block--vertical"
          @click="prevalidate('activeDaysError')"
        >
          <div class="row row--last">
            <span class="block__label" :class="activeDaysIsInvalid"
              >Active days:</span
            >
            <ul class="days">
              <CircularToggle
                label="Mo"
                :isOn="activeDays.includes('Mo')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="Tu"
                :isOn="activeDays.includes('Tu')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="We"
                :isOn="activeDays.includes('We')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="Th"
                :isOn="activeDays.includes('Th')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="Fr"
                :isOn="activeDays.includes('Fr')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="Sa"
                :isOn="activeDays.includes('Sa')"
                @statusUpdate="toggleActiveDayStatus"
              />
              <CircularToggle
                label="Su"
                :isOn="activeDays.includes('Su')"
                @statusUpdate="toggleActiveDayStatus"
              />
            </ul>
          </div>
          <p class="error-text" v-if="activeDaysError">{{ activeDaysError }}</p>
        </div>
      </div>

      <div class="form-buttons">
        <div class="form-buttons__wrapper">
          <PrimaryFormButton
            :label="secondaryBtnLabel"
            :icon="'leftArrow'"
            :isDisabled="false"
            :executesMainAction="false"
            @click="leavePage"
          />
          <PrimaryFormButton
            :label="primaryBtnLabel"
            :icon="'megaphone'"
            :isDisabled="!formHasEnoughDetails"
            :executesMainAction="true"
            @click="validateAndSubmit"
          />
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import LabeledToggle from "../elements/LabeledToggle.vue";
import RegularTypeInput from "../elements/RegularTypeInput.vue";
import BasicPill from "../elements/BasicPill.vue";
import BasicCheckbox from "../elements/BasicCheckbox.vue";
import CircularToggle from "../elements/CircularToggle.vue";
import PrimaryFormButton from "../elements/PrimaryFormButton.vue";

export default {
  components: {
    LabeledToggle,
    RegularTypeInput,
    BasicPill,
    BasicCheckbox,
    CircularToggle,
    PrimaryFormButton,
  },

  data() {
    return {
      name: "",
      scope: "",
      trigger: "",
      listedChannels: [],
      activeDays: [],
      isEnabled: "",
      startHours: "",
      startMinutes: "",
      startMeridiem: "",
      endHours: "",
      endMinutes: "",
      endMeridiem: "",
      isActiveAllDay: "",
      allowanceHours: "",
      allowanceMinutes: "",
      sourceError: null,
      intervalError: null,
      allowanceError: null,
      activeDaysError: null,
    };
  },

  watch: {
    startAndEndTimesAreEqual(newValue) {
      if (newValue === true) {
        this.isActiveAllDay = true;
      }
    },
  },

  computed: {
    channelsNote() {
      if (this.scope === "inclusive") {
        return "(you have not added any channels yet)";
      }
      return "(not listing any channel will block all videos)";
    },
    startAndEndTimesAreEqual() {
      if (
        this.startHours === this.endHours &&
        this.startMinutes === this.endMinutes &&
        this.startMeridiem === this.endMeridiem
      ) {
        return true;
      }

      return false;
    },
    newCommandTemplate() {
      return this.$store.getters["commands/newCommandTemplate"];
    },
    commandToEdit() {
      return this.$store.getters["commands/commandToEdit"];
    },
    formHasEnoughDetails() {
      if (this.scope === "inclusive" && this.listedChannels.length === 0) {
        return false;
      }

      if (this.name === "") {
        return false;
      }

      return true;
    },
    intervalIsInvalid() {
      return {
        "invalid-section": this.intervalError,
      };
    },
    allowanceIsInvalid() {
      return {
        "invalid-section": this.allowanceError,
      };
    },
    activeDaysIsInvalid() {
      return {
        "invalid-section": this.activeDaysError,
      };
    },
    primaryBtnLabel() {
      return this.commandToEdit ? "Update This Command" : "Utter This Command";
    },
    secondaryBtnLabel() {
      return this.commandToEdit ? "Discard Changes" : "Discard Command";
    },
  },

  methods: {
    onScopeUpdate(selectedValue) {
      this.scope = selectedValue;
    },
    addChannel(channelName) {
      this.sourceError = null;

      channelName = channelName.trim();

      if (channelName === "") return;

      let error = null;

      if (this.listedChannels.includes(channelName)) {
        error = `${channelName} is already listed`;
      }

      if (
        channelName.startsWith("https://") ||
        channelName.startsWith("http://")
      ) {
        error = "Please enter a channel name instead of a URL";
      }

      if (error) {
        this.sourceError = error;
        setTimeout(() => {
          this.sourceError = null;
        }, error.length * 75);
        return;
      }

      this.listedChannels.unshift(channelName);
    },
    onClosePill(index) {
      this.listedChannels.splice(index, 1);
    },
    onTriggerUpdate(selectedValue) {
      this.trigger = selectedValue;
    },
    onCheckboxUpdate(latestState) {
      this.isActiveAllDay = latestState;
    },
    onStartMeridiemUpdate(selectedValue) {
      this.startMeridiem = selectedValue;
    },
    onEndMeridiemUpdate(selectedValue) {
      this.endMeridiem = selectedValue;
    },
    toggleActiveDayStatus(option) {
      if (option.isActive) {
        this.activeDays.push(option.value);
      } else {
        this.activeDays = this.activeDays.filter((day) => day !== option.value);
      }
    },
    prevalidate(errorTracker) {
      this[errorTracker] = null;
    },
    formatHours(hours, targetFormat, type, meridiem) {
      if (targetFormat === "12-hour-format") {
        this[`${meridiem.target}Meridiem`] = hours < 12 ? "am" : "pm";

        if (hours > 12) {
          hours = hours - 12;
        }
      }

      // format netural, needed for both
      if (hours == 0) {
        hours = 12;
      }

      if (targetFormat === "24-hour-format") {
        if (meridiem.position === "pm" && hours != 12) {
          hours = hours + 12; // 12 pm will stay as 12
        } else if (meridiem.position === "am" && hours == 12) {
          hours = 0; // 12 am will become 0
        }
      }

      return type === "string" ? String(hours) : Number(hours);
    },
    formatMinutes(minutes, type) {
      if (minutes == 0 && type === "string") {
        return "00";
      } else {
        return type === "string" ? String(minutes) : Number(minutes);
      }
    },
    formatAllowance(minutesCap, timeUnit) {
      const hours = Math.floor(minutesCap / 60);

      if (timeUnit === "hours") {
        return `${hours}`;
      } else {
        const minutes = minutesCap - hours * 60;
        return `${minutes}`;
      }
    },
    formatSplitAllowance(hours, minutes) {
      return Number(hours) * 60 + Number(minutes);
    },
    leavePage() {
      this.$store.dispatch("commands/clearCommandToEdit");
      this.$store.dispatch("navigateToView", {
        view: "commandsList",
        component: "CommandsList",
      });
    },
    validateAndSubmit() {
      if (!this.formHasEnoughDetails) return;

      // reset errors
      this.sourceError = null;
      this.intervalError = null;
      this.allowanceError = null;
      this.activeDaysError = null;

      // grab all inputs for revision and potential submission
      const name = this.name;
      const scope = this.scope;
      const trigger = this.trigger;
      const listedChannels = this.listedChannels;
      const activeDays = this.activeDays;
      const isEnabled = this.isEnabled;
      const startHours = this.startHours;
      const startMinutes = this.startMinutes;
      const startMeridiem = this.startMeridiem;
      const endHours = this.endHours;
      const endMinutes = this.endMinutes;
      const endMeridiem = this.endMeridiem;
      const isActiveAllDay = this.isActiveAllDay;
      const allowanceHours = this.allowanceHours;
      const allowanceMinutes = this.allowanceMinutes;

      // validate either interval or allowance
      if (trigger === "direct" && !isActiveAllDay) {
        // validate 'Active Interval' inputs:
        for (let time of [
          "startHours",
          "startMinutes",
          "endHours",
          "endMinutes",
        ]) {
          if (this[time] === "" || isNaN(this[time])) {
            this[time] = "";
            this.intervalError =
              "Some time inputs were empty or had letters in them. Please make sure you only use numbers.";
          }
        }

        if (startHours > 12 || endHours > 12) {
          this.intervalError = "Make sure you don't set hours above 12.";
        }

        if (startMinutes > 59 || endMinutes > 59) {
          this.intervalError = "Make sure you don't set minutes above 59.";
        }
      } else if (trigger === "allowance") {
        for (let time of ["allowanceHours", "allowanceMinutes"]) {
          if (this[time] === "" || isNaN(this[time])) {
            this.allowanceError =
              "Some time inputs were empty or had letters in them. If you need less than an hour, enter 0 for hours.";
          }
        }

        if (allowanceHours > 23) {
          this.allowanceError =
            "Oops. A typo? Make sure that hours do not exceed 23.";
        }

        if (allowanceMinutes > 59) {
          this.allowanceError =
            "Opps. A typo? Make sure that minutes do not exceed 59. If you need more time you can use the hours field";
        }
      }

      // validate active days
      if (activeDays.length === 0) {
        this.activeDaysError =
          "Please select at least one day for your command to be active.";
      }

      // don't submit if there are any invalid inputs
      if (this.intervalError || this.allowanceError || this.activeDaysError) {
        return;
      }

      const generatedCommand = {
        name: name,
        type: [trigger, scope],
        listedSources: [...listedChannels],
        activeDays: [...activeDays],
        isEnabled: isEnabled,
      };

      if (trigger === "direct") {
        generatedCommand.interval = {
          isActiveAllDay: isActiveAllDay,
          startTime: {
            hours: this.formatHours(
              Math.floor(startHours),
              "24-hour-format",
              "number",
              { position: startMeridiem }
            ),
            minutes: Math.floor(startMinutes),
          },
          endTime: {
            hours: this.formatHours(
              Math.floor(endHours),
              "24-hour-format",
              "number",
              { position: endMeridiem }
            ),
            minutes: Math.floor(endMinutes),
          },
        };

        // make start and end times match if isActiveAllDay was selected
        if (isActiveAllDay) {
          generatedCommand.interval.endTime.hours =
            generatedCommand.interval.startTime.hours;
          generatedCommand.interval.endTime.minutes =
            generatedCommand.interval.startTime.minutes;
        }

        // force isActiveAllDay when appropriate even if users didn't check it
        if (
          generatedCommand.interval.startTime.hours ===
            generatedCommand.interval.endTime.hours &&
          generatedCommand.interval.startTime.minutes ===
            generatedCommand.interval.endTime.minutes
        ) {
          generatedCommand.interval.isActiveAllDay = true;
        }
      } else {
        generatedCommand.dailyLimit = Math.floor(
          this.formatSplitAllowance(allowanceHours, allowanceMinutes)
        );

        // set a default interval anyway and ensure the command is
        // marked as "isActiveAllDay" (keep in mind that allowance
        // commands are technically active all day)
        generatedCommand.interval = {
          isActiveAllDay: true,
          startTime: {
            hours: 0,
            minutes: 0,
          },
          endTime: {
            hours: 0,
            minutes: 0,
          },
        };
      }

      this.$store.dispatch("commands/saveCommand", generatedCommand);
      this.leavePage();
    },
  },
  mounted() {
    // initialize template:
    const template = this.commandToEdit
      ? this.commandToEdit
      : this.newCommandTemplate;

    this.name = template.name;
    this.scope = template.type.includes("inclusive")
      ? "inclusive"
      : "exclusive";
    this.trigger = template.type.includes("direct") ? "direct" : "allowance";

    // // BUG FIXES:
    // Should be doing this but can't because of a bug
    // this.listedChannels = template.listedSources;
    // this.activeDays = template.activeDays;
    const aCommandToEditExists = Boolean(this.commandToEdit);

    this.listedChannels = aCommandToEditExists ? template.listedSources : []; // doing this because of a Vue bug
    this.activeDays = aCommandToEditExists
      ? template.activeDays
      : ["Mo", "Tu", "We", "Th", "Fr"]; // doing this because of a Vue bug
    // ------

    this.isEnabled = template.isEnabled;

    if (template.type.includes("direct")) {
      // (note: meridiems will be set automatically by formatHours)
      this.startHours = this.formatHours(
        template.interval.startTime.hours,
        "12-hour-format",
        "string",
        { target: "start" }
      );
      this.startMinutes = this.formatMinutes(
        template.interval.startTime.minutes,
        "string"
      );
      this.endHours = this.formatHours(
        template.interval.endTime.hours,
        "12-hour-format",
        "string",
        { target: "end" }
      );
      this.endMinutes = this.formatMinutes(
        template.interval.endTime.minutes,
        "string"
      );
      this.isActiveAllDay = template.interval.isActiveAllDay;
    } else {
      // define sample values in case a prop is toggled and I need placeholders
      this.startHours = "11";
      this.startMinutes = "00";
      this.startMeridiem = "am";
      this.endHours = "7";
      this.endMinutes = "30";
      this.endMeridiem = "pm";
      this.isActiveAllDay = false;
    }

    if (template.dailyLimit) {
      this.allowanceHours = this.formatAllowance(template.dailyLimit, "hours");
      this.allowanceMinutes = this.formatAllowance(
        template.dailyLimit,
        "minutes"
      );
    } else {
      this.allowanceHours = "0";
      this.allowanceMinutes = "30";
    }
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: hidden;
}

form {
  border: 2px solid colors.$softPurple;
  border-radius: 6rem;

  height: 100%;
  width: 75%;
  overflow: hidden;
  margin: 0 auto;
  padding: 5rem 7.5% 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: 11rem;
}

.command-name {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 2px solid colors.$grayFive;
  padding-bottom: 2.5rem;
  color: colors.$distantStar;
  @include type.headerTwo;
  text-align: center;
}

.command-controls {
  overflow: auto;
  padding-right: 5rem;

  /*width*/
  &::-webkit-scrollbar {
    width: clamp(3px, 2.25rem, 8px);
  }
  /*track*/
  &::-webkit-scrollbar-track {
    background: colors.$grayFive;
    border-style: none;
    border-radius: 10rem;
  }
  /*thumb*/
  &::-webkit-scrollbar-thumb {
    background: colors.$auroraGradient;
    border-radius: 10rem;
  }
  /*thumb pressed*/
  &::-webkit-scrollbar-thumb:active {
    background: colors.$aquaGradient;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: colors.$grayThree colors.$grayFive;
  }

  .block {
    display: flex;
    align-items: center;
    margin-bottom: 8rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      @include type.bodyOne;
      margin-right: 5rem;

      &.invalid-section {
        color: colors.$watermelonSignal;
      }
    }

    &.block--vertical {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4rem;

      &.row--last {
        margin-bottom: 0;
      }
    }
  }

  .informative-text {
    margin-top: 2rem;
    color: colors.$grayThree;
  }

  .guiding-text {
    color: colors.$grayTwo;
  }

  .error-text {
    margin-top: 5rem;
    color: colors.$watermelonSignal;
  }

  .pills {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .block__interval {
    .interval-definition {
      display: flex;
      align-items: center;
    }

    .checkbox {
      margin-left: 2.5rem;
      margin-right: 5rem;
    }

    .colons {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .meridiem-toggle {
      margin-left: 1.5rem;
    }

    .from {
      margin-right: 2.5rem;
    }

    .to {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
    }
  }

  .allowance-definition {
    .guiding-text {
      margin-left: 1.5rem;
    }

    .inline-section {
      margin-left: 5rem;
    }
  }

  .days {
    display: flex;

    li {
      margin-right: 1.5rem;
    }

    li:last-child {
      margin-right: 0;
    }
  }
}

.form-buttons {
  display: flex;
  justify-content: center;

  &__wrapper {
    border: 2px solid colors.$softPurple;
    border-radius: 3rem 3rem 0 0;
    border-bottom: none;
    display: flex;
    overflow: hidden;
  }
}
</style>
